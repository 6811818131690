// import rightImg from "images/hero-right1.png";
import rightImg from "images/about/Maps.png";
import React, { FC } from "react";
import SectionFounder, { People } from "./SectionFounder";
import SectionStatistic from "./SectionStatistic";
import { Helmet } from "react-helmet";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionHero from "./SectionHero";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import SectionPromo3 from "components/SectionPromo3";
import NcImage from "shared/NcImage/NcImage";
import { useLocation } from "react-router-dom";

export interface PageAboutProps {
  className?: string;
}

const FOUNDER_DEMO: People[] = [
  {
    id: "1",
    name: `Niamh O'Shea`,
    job: "Co-founder and Chief Executive",
    avatar:
      "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=400&q=80"
  },
  {
    id: "4",
    name: `Danien Jame`,
    job: "Co-founder and Chief Executive",
    avatar:
      "https://images.unsplash.com/photo-1568602471122-7832951cc4c5?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=400&q=80"
  },
  {
    id: "3",
    name: `Orla Dwyer`,
    job: "Co-founder, Chairman",
    avatar:
      "https://images.unsplash.com/photo-1560365163-3e8d64e762ef?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=400&q=80"
  },
  {
    id: "2",
    name: `Dara Frazier`,
    job: "Co-Founder, Chief Strategy Officer",
    avatar:
      "https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=400&q=80"
  }
];

const PageAbout: FC<PageAboutProps> = ({ className = "" }) => {
  const location = useLocation();
  const path = location.pathname;
  return (
    <div
      className={`nc-PageAbout overflow-hidden relative ${className}`}
      data-nc-id="PageAbout"
    >
      <Helmet>
        <title>Terms and Conditions || Oz Cleaning Supplies</title>

        <meta
          name="description"
          content="Welcome to Oz Cleaning Supplies, your premier destination for top-quality cleaning
    supplies in Dandenong!"
        />

        <meta name="keywords" content="Cleaning supplies Dandenong" />
        <link rel="canonical" href={path + "/"} />

        <script type="application/ld+json">{`
  {
  "@context" : "http://schema.org",
  "@type" : "LocalBusiness",
  "name": "Oz Cleaning Supplies",
  "image" : [ "https://backendozecomadmin.ozcleaningsupplies.com.au/uploads/logo/1670255682167.jpeg", "https://www.ozcleaningsupplies.com.au/" ],
  "telephone": "+61 0397940654",
  "email": "info@ozcleaningsupplies.com.au",
  "address": {
    "@type": "PostalAddress",
    "streetAddress" : "167 Cheltenham Road",
    "addressLocality": "Dandenong",
    "addressRegion" : "VIC ",
    "addressCountry": "Australia",
    "postalCode" : "3175"
  },
  "url": "https://www.ozcleaningsupplies.com.au${path}"
}
    `}</script>
      </Helmet>

      {/* ======== BG GLASS ======== */}
      {/* <BgGlassmorphism /> */}

      <div className="container py-16 lg:py-28 space-y-16 lg:space-y-14">
        {/* <SectionHero
          rightImg={rightImg}
          heading="👋 About Us."
          btnText=""
          subHeading="We’re impartial and independent, and every day we create distinctive, world-class programmes and content which inform, educate and entertain millions of people in the around the world."
        /> */}

        <div className="flex items-center justify-items-center justify-center grid grid-cols-1 lg:grid-cols-1 gap-10 ">
          <div className="">
            <h2 className="text-lg font-semibold text-neutral-900 md:text-2xl xl:text-2xl dark:text-neutral-100">
              TERMS AND CONDITIONS
            </h2>
            <div className="text-left mt-6">
              <span>
                Welcome to the Silvermill Corporate Discount Program website
                (www.cdp.silvermillgroup.lk). These Terms and Conditions govern
                your use of our website and the purchase and sale of products
                from our platform. By accessing and using our website, you agree
                to comply with these terms. Please read them carefully before
                proceeding with any transactions.
              </span>
              <h2 className="text-base font-semibold text-neutral-900 md:text-xl xl:text-xl dark:text-neutral-100 mt-6">
                Use of the Website
              </h2>
              <ol
                type="a"
                className="list-[lower-alpha] text-sm xl:text-base text-neutral-6000 dark:text-neutral-400 mt-3 ml-5"
              >
                <li>
                  You are responsible for maintaining the confidentiality of
                  your account information, including your username and
                  password.
                </li>
                <li>
                  You agree to provide accurate and current information during
                  the registration and checkout process.
                </li>
                <li>
                  You may not use our website for any unlawful or unauthorized
                  purposes.
                </li>
                <li>
                  The Silvermill Corporate Discount Program is an exclusive
                  offer provided to our Corporate Partners who have joined us to
                  provide the Program benefits to their employees. All products
                  ordered will be delivered to the venue of our Corporate
                  Partners. Once you register, we will contact your employer to
                  confirm that you are an employee of our Corporate Partners,
                  after which your account will be activated for you to purchase
                  products on our website. By registering on our website, you
                  consent to the above.
                </li>
              </ol>

              <h2 className="text-base font-semibold text-neutral-900 md:text-xl xl:text-xl dark:text-neutral-100 mt-8">
                Product Information and Pricing
              </h2>
              <ol
                type="a"
                className="list-[lower-alpha] text-sm xl:text-base text-neutral-6000 dark:text-neutral-400 mt-3 ml-5"
              >
                <li>
                  We strive to provide accurate product descriptions, images,
                  and pricing information. However, we do not guarantee the
                  accuracy or completeness of such information.
                </li>
                <li>
                  Prices are subject to change without notice. Any promotions or
                  discounts are valid for a limited time and may be subject to
                  additional terms and conditions.
                </li>
              </ol>

              <h2 className="text-base font-semibold text-neutral-900 md:text-xl xl:text-xl dark:text-neutral-100 mt-8">
                Orders and Payments
              </h2>
              <ol
                type="a"
                className="list-[lower-alpha] text-sm xl:text-base text-neutral-6000 dark:text-neutral-400 mt-3 ml-5"
              >
                <li>
                  By placing an order on our website, you are making an offer to
                  purchase the selected products.
                </li>
                <li>
                  We reserve the right to refuse or cancel any order for any
                  reason, including but not limited to product availability,
                  errors in pricing or product information, or suspected
                  fraudulent activity.
                </li>
                <li>
                  You agree to provide valid and up-to-date payment information
                  and authorize us to charge the total order amount, including
                  applicable taxes and shipping fees, to your chosen payment
                  method.
                </li>
                <li>
                  We use trusted third-party payment processors to handle your
                  payment information securely. We do not store or have access
                  to your full payment details.
                </li>
              </ol>

              <h2 className="text-base font-semibold text-neutral-900 md:text-xl xl:text-xl dark:text-neutral-100 mt-8">
                Shipping and Delivery
              </h2>
              <span className="block text-sm xl:text-base text-neutral-6000 dark:text-neutral-400 mt-3">
                a. We will make reasonable efforts to ensure timely shipping and
                delivery of your orders.
                <br /> b. Shipping and delivery times provided are estimates and
                may vary based on your location and other factors.
              </span>

              <h2 className="text-base font-semibold text-neutral-900 md:text-xl xl:text-xl dark:text-neutral-100 mt-8">
                Returns
              </h2>
              <span className="block text-sm xl:text-base text-neutral-6000 dark:text-neutral-400 mt-3">
                a. Our Returns Policy governs the process and conditions for
                returning products. Please refer to the policy provided on our
                website for more information.{" "}
              </span>

              <h2 className="text-base font-semibold text-neutral-900 md:text-xl xl:text-xl dark:text-neutral-100 mt-8">
                Intellectual Property
              </h2>
              <ol
                type="a"
                className="list-[lower-alpha] text-sm xl:text-base text-neutral-6000 dark:text-neutral-400 mt-3 ml-5"
              >
                <li>
                  All content and materials on our website, including but not
                  limited to text, images, logos, and graphics, are protected by
                  intellectual property rights and are the property of the
                  Silvermill Corporate Discount Program (
                  <a
                    href="https://www.cdp.silvermillgroup.lk"
                    className="underline"
                  >
                    www.cdp.silvermillgroup.lk
                  </a>
                  ) or its licensors.
                </li>
                <li>
                  You may not use, reproduce, distribute, or modify any content
                  from our website without our prior written consent.
                </li>
              </ol>

              <h2 className="text-base font-semibold text-neutral-900 md:text-xl xl:text-xl dark:text-neutral-100 mt-8">
                Limitation of Liability
              </h2>
              <ol
                type="a"
                className="list-[lower-alpha] text-sm xl:text-base text-neutral-6000 dark:text-neutral-400 mt-3 ml-5"
              >
                <li>
                  In no event shall the Silvermill Corporate Discount Program (
                  <a
                    href="https://www.cdp.silvermillgroup.lk"
                    className="underline"
                  >
                    www.cdp.silvermillgroup.lk
                  </a>
                  ), its directors, employees, or affiliates be liable for any
                  direct, indirect, incidental, special, or consequential
                  damages arising out of or in connection with your use of our
                  website or the purchase and use of our products.
                </li>
                <li>
                  We make no warranties or representations, express or implied,
                  regarding the quality, accuracy, or suitability of the
                  products offered on our website.
                </li>
              </ol>

              <h2 className="text-base font-semibold text-neutral-900 md:text-xl xl:text-xl dark:text-neutral-100 mt-8">
                Amendments and Termination{" "}
              </h2>
              <span className="block text-sm xl:text-base text-neutral-6000 dark:text-neutral-400 mt-3">
                We reserve the right to modify, update, or terminate these Terms
                and Conditions at any time without prior notice. We encourage
                you to review this Privacy Policy periodically to stay informed
                about our data practices
              </span>
            </div>
          </div>
        </div>

        {/* <SectionFounder /> */}
        {/* <div className="relative py-16">
          <BackgroundSection />
          <SectionClientSay />
        </div>

        <SectionStatistic /> */}

        {/* <SectionPromo3 /> */}
      </div>
    </div>
  );
};

export default PageAbout;
