import React, { useContext, useEffect, useRef, useState } from "react";

import { Link, useHistory, useLocation } from "react-router-dom";

import arrowright from "images/arrowright.png";
import arrowleft from "images/arrowleft.png";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import { Swiper as SwiperCore } from "swiper";
import { Pagination, Navigation, Autoplay } from "swiper/modules";
import { IMAGE_SERVER_URL_DEV } from "Constant/System";
import save from "images/save.png";
import CartContext from "Context/CartContext";
import jwtDecode from "jwt-decode";
import { addToCard } from "Services/API/Post";
import toast from "react-hot-toast";
import NotifyAddTocart from "./NotifyAddTocart";
import CartCount from "Constant/CartCount";
import { addToast } from "shared/Toast";
import { getCartList } from "Services/API/Get";

SwiperCore.use([Pagination, Navigation, Autoplay]);

interface HomeProductSliderProps {
  nProducts: any[]; // Ideally, define a type for your product items
  currUser: any;
}

export const HomeProductSlider: React.FC<HomeProductSliderProps> = ({
  nProducts,
  currUser
}) => {
  const swiperRef = useRef<SwiperCore | null>(null);

  useEffect(() => {
    // Wait for component to fully render, then initialize Swiper features
    const timer = setTimeout(() => {
      if (swiperRef.current) {
        swiperRef.current.navigation.update();
        swiperRef.current.pagination.update();
        swiperRef.current.autoplay.start(); // Force autoplay to start

        swiperRef.current.slideToLoop(1, 0); // Slide to the next slide instantly
        swiperRef.current.slideToLoop(0, 0);
      }
    }, 100); // Delay slightly to allow Swiper to initialize

    return () => clearTimeout(timer); // Cleanup timer on component unmount
  }, [nProducts]);

  const initialSlideIndex = Math.floor(nProducts.length / 2);
  return (
    <div className="swiper-container pt-10 relative w-full">
      {/* Custom Navigation Buttons */}
      <button
        className="prev-btn absolute left-[-5%] md:left-[-5%] top-1/2 transform -translate-y-1/2 z-10 rounded-full p-2"
        onClick={() => swiperRef.current?.slidePrev()}
      >
        <img src={arrowleft} alt="Previous" className="w-6 h-6" />
      </button>
      <button
        className="next-btn absolute right-[-5%] md:right-[-5%] top-1/2 transform -translate-y-1/2 z-10 rounded-full p-2"
        onClick={() => swiperRef.current?.slideNext()}
      >
        <img src={arrowright} alt="Next" className="w-6 h-6" />
      </button>

      <Swiper
        onSwiper={(swiper: SwiperCore) => (swiperRef.current = swiper)}
        spaceBetween={55}
        //   centeredSlides={true}
        //   initialSlide={initialSlideIndex}
        loop={true}
        pagination={{
          clickable: true, // Ensure pagination is clickable
          el: ".custom-pagination-p" // Explicitly specify Swiper's default pagination selector
        }}
        navigation={{
          nextEl: ".next-btn",
          prevEl: ".prev-btn"
        }}
        autoplay={{
          delay: 3000, // 3 seconds between slides
          disableOnInteraction: false // Continue autoplay after user interactions
        }}
        modules={[Pagination, Navigation, Autoplay]}
        className="myswiper w-full"
        breakpoints={{
          640: { slidesPerView: 1 },
          768: { slidesPerView: 2 },
          1024: { slidesPerView: 3 }
        }}
      >
        {nProducts?.length > 0 &&
          nProducts
            .filter((item) => item?.homepage === 1)
            .map((item, index) => (
              <SwiperSlide
                key={index}
                className="md:w-[32%] flex p-5 px-10 relative bg-[#fffaef] rounded-3xl"
              >
                <ProductCard item={item} currUser={currUser} />
              </SwiperSlide>
            ))}
      </Swiper>
      {/* Swiper's default pagination element */}
      <div className="custom-pagination-p gap-[5px] flex justify-center mt-8"></div>
    </div>
  );
};
const ProductCard = ({ item, currUser }: { item: any; currUser?: any }) => {
  const [value, setValue] = useState<any | null>(0);
  const [lopen, setlopen] = React.useState(true);

  let discount = (
    ((parseFloat(item.mrp) - parseFloat(item.specialprice)) /
      parseFloat(item.mrp)) *
    100
  ).toString();

  useEffect(() => {
    setValue(1);
  }, [1]);
  const { cart, setCart } = useContext(CartContext);
  const handleClickDecrement = () => {
    if (1 >= value) return;
    setValue((state: number) => {
      return state - 1;
    });
  };
  const handleClickIncrement = () => {
    if (99 <= value) return;
    setValue((state: number) => {
      return state + 1;
    });
  };

  const notifyAddTocart = () => {
    const getToken: any = localStorage.getItem(
      `${window.location.hostname}.login`
    );

    // if (!getToken) {
    //   history.push("/login");
    //   window.scroll(0, 0);
    //   return true;
    // }

    let tempId: any = "";

    let userId: any = "";
    let userstatus: any = 0;

    if (localStorage.getItem("tempUser")) {
      tempId = localStorage.getItem("tempUser");
    }

    if (getToken) {
      const { data } = JSON.parse(getToken);
      const { id }: any = jwtDecode(data);
      const { status }: any = jwtDecode(data);

      // console.log("ss",jwtDecode(data))
      userId = id;
      userstatus = status
    }

    let error = false;
    let cartDetails = {
      productId: item?.id.toString(),
      userId: userId ? userId : tempId,
      buyone: item?.buyone,
      qty: value,
      color: "",
      size: "",
      fileType: "",
      image: ""
    };

    if (userId ) {
      if(userstatus === 1)
      {

     
      addToCard(cartDetails)
        .then((data) => {
          setlopen(false);
          // addToast(`${product?.productName} Added to Cart`, { appearance: "success", autoDismiss: true });
          toast.custom(
            (t) => (
              <NotifyAddTocart
                productImage={`${IMAGE_SERVER_URL_DEV}${
                  JSON.parse(item?.imgArray)[0].path
                }`}
                qualitySelected={value}
                show={t.visible}
                sizeSelected={""}
                variantActive={0}
                selectedProductColor={""}
                selectedProduct={item}
              />
            ),
            { position: "top-right", id: "nc-product-notify", duration: 3000 }
          );

          getCartList().then((res) => {
            setCart(res.data?.cart_items);
            // console.log(res.data?.cart_items)
          });
          // this.setState({
          //   color2: "#645bff",
          //   alertClass: "success",
          //   msg: `${this.state.productData?.displayName} Added to Cart`,
          //   open_toast: true,
          // });
        })
        .catch((err) => {
          setlopen(false);
          addToast(`${err.response.data.message}`, "warning");
          // this.setState({
          //   color2: "#645bff",
          //   alertClass: "success",
          //   msg: `${this.state.productData?.displayName} product add failed`,
          //   open_toast: true,
          // });
        });

      } else {
        addToast(`Your acccount is not activated`, "warning");
      }
    } else {
      addToast(`Please Log in to buy products`, "warning");
    }
  };

  return (
    <div className="w-full flex flex-col items-center gap-2 justify-center ">
      {item?.specialprice && (
        <div className="absolute right-[-11%] z-[100] top-[-9%]">
          <div className="relative flex justify-center items-center w-[90px] h-[90px]">
            <img src={save} className="w-full" alt="" />
            <div className="absolute inset-0 flex items-center font-berthold pl-2 flex-col justify-center text-center text-white text-sm leading-tight transform rotate-[25deg] -translate-y-2">
              <div>SAVE</div>
              <div className="text-[24px]">{parseInt(discount)}%</div>
            </div>
          </div>
        </div>
      )}

      <div className="text-[#010a4f] font-berthold text-center text-2xl">
        {item?.productName}
      </div>
      {/* <div className="text-[#010a4f] font-berthold text-center text-2xl pb-3">
        500 ml
      </div> */}
      {item?.imgArray && (
        <img
          src={`${IMAGE_SERVER_URL_DEV}${JSON.parse(item?.imgArray)[0].path}`}
          className="  w-full aspect-1"
          alt=""
        />
      )}

      {item?.buyone === 1 && (
        <div className=" font-berthold text-red-600">Buy One Get 1 FREE</div>
      )}

      <div className=" w-full flex justify-center font-berthold text-[#010a4f] text-4xl items-center gap-2">
        <div
          className={`${
            item?.specialprice && " text-3xl text-[#8a8888] line-through "
          }`}
        >
          Rs.{item?.mrp}
        </div>
        {item?.specialprice && <div className="">Rs.{item?.specialprice}</div>}{" "}
      </div>
      {/* <Link
          to={`/product-detail/${item?.productId}`}
          className={`font-berthold tracking-wider w-fit px-5 py-2 text-sm font-bold rounded-lg uppercase
             bg-[#010a4f] text-white`}
        >
          View Product
        </Link> */}

      <>
        <div className="flex gap-2 flex-col items-center ">
          <div className="flex text-[#010a4f] text-2xl items-center rounded-md px-3 py-1 gap-3">
            <button    className="text-[#010a4f] " onClick={handleClickDecrement} disabled={1 >= value}>
              -
            </button>
            <span>{value}</span>
            <button
              className="text-[#010a4f]"
              disabled={!currUser}
              onClick={handleClickIncrement}
            >
              +
            </button>
          </div>
          <button
            // disabled={!currUser}
            onClick={notifyAddTocart}
            className={`font-berthold  w-fit px-5 py-1 text-2xl font-medium rounded-lg uppercase ${
              currUser ? "bg-[#02871a]" : "bg-[#02871a]"
            }  text-white`}
          >
            Add to cart
          </button>
        </div>
      </>
    </div>
  );
};
