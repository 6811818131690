import React, { useContext, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, useHistory, useLocation } from "react-router-dom";
import { addToast } from "shared/Toast";
import heroimg from "images/hero-banner.png";
import aboutdes from "images/about-des.png";
import aboutphotos from "images/aboutphotos.png";
import aboutvideo from "images/aboutvideo.png";
import aboutbeyond from "images/aboutbeyond.png";
import aboutstep from "images/aboutstep.png";
import productTitle from "images/productsTitle.png";
import abouthero from "images/about-hero.png";
import pageBottom from "images/pagebottom.png";
import bannerjoin from "images/banner-join.png";
import pagetop from "images/page-top.png";
import notime from "images/no-time.png";
import reviewimg from "images/review-img.png";
// import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Swiper as SwiperCore } from "swiper";
import { Pagination, Navigation } from "swiper/modules";
import { getAllProducts, getQR, getTestimonial } from "Services/API/Get";
import { IMAGE_SERVER_URL_DEV } from "Constant/System";
import jwtDecode from "jwt-decode";
import { addToCard } from "Services/API/Post";
import NotifyAddTocart from "components/NotifyAddTocart";
import CartCount from "Constant/CartCount";
import CartContext from "Context/CartContext";
import toast from "react-hot-toast";

import qrCode from "images/qr-code.png";

function PageAboutUs() {
  const [nProducts, setnProducts] = useState([]);
  const [reviews, setreviews] = useState([]);
  const [filterSortValue, setFilterSortValue] = useState("");
  const [selectedSize, setselectedSize] = useState("");
  const [selectedColour, setselectedColour] = useState("");
  const [selectedCat, setselectedCat] = useState<any | null>("");
  const [minPrice, setminPrice] = useState<any | null>(0);
  const [maxPrice, setmaxPrice] = useState<any | null>(2000);
  const [selectedSub, setselectedSub] = useState<any>("");
  const [search, setsearch] = useState("");
  const [pageLimit, setpageLimit] = useState(68);
  const [currentPage, setCurrentPage] = useState(1);
const [qrimg, setqrimg] = useState(null)
  const [swiperInstance, setSwiperInstance] = useState<SwiperCore | null>(null);

  useEffect(() => {
    if (swiperInstance) {
      swiperInstance.navigation.update();
    }
  }, [nProducts, swiperInstance]);

  const getQRData = async() => {
    await getQR()
    .then((response) => {
   
      console.log(">>>>>>>>>>>>>>>>>>", response?.data);
      setqrimg(response.data[0]?.image)
     
    })
    .catch(() => {
     
    });
  }

  useEffect(() => {
    getQRData()
  }, []);

  console.log(qrimg)

 
  const getTestimonials = async () => {
    try {
      await getTestimonial()
        .then((response: any) => {
          console.log(">>>>>>>>>>>>>>>>>>", response?.data);
          setreviews(response.data);
        })
        .catch(() => {
          console.log("T&C ");
        });
    } catch (error) {
      console.log("T&C ", error);
    }
  };

  useEffect(() => {
    // getItemProducts();
    getTestimonials();
  }, []);

  const [activeIndex, setActiveIndex] = useState(2);
  return (
    <div className="nc-PageHome relative overflow-hidden ">
      <Helmet>
        <title>Silvermill | About Us</title>
        <meta
          name="description"
          content="Welcome to Oz Cleaning Supplies, your premier destination for top-quality cleaning
    supplies in Dandenong!"
        />

        <meta name="keywords" content="Cleaning supplies Dandenong" />
        <link rel="canonical" href="/" />
        <script type="application/ld+json">{`
  {
  "@context" : "http://schema.org",
  "@type" : "LocalBusiness",
  "name": "Silvermill",
  "image" : [ "https://backendozecomadmin.ozcleaningsupplies.com.au/uploads/logo/1670255682167.jpeg", "https://www.ozcleaningsupplies.com.au/" ],
  "telephone": "+61 0397940654",
  "email": "info@ozcleaningsupplies.com.au",
  "address": {
    "@type": "PostalAddress",
    "streetAddress" : "167 Cheltenham Road",
    "addressLocality": "Dandenong",
    "addressRegion" : "VIC ",
    "addressCountry": "Australia",
    "postalCode" : "3175"
  },
  "url": "https://www.ozcleaningsupplies.com.au/"
}
    `}</script>
      </Helmet>
      {/* <div className="xl:w-full lg:h-[650px] 2xl:h-[768px]   "> */}
      <div className="xl:w-full   ">
        <img className=" xl:w-full   " src={abouthero} alt="" />
      </div>
      <div className="xl:w-full   ">
        <img className=" xl:w-full   " src={aboutdes} alt="" />
      </div>

      <div className="w-full flex flex-col">
        <img src={aboutphotos} alt="" />
      </div>

      <div className="w-full flex flex-col items-center justify-center bg-[#010a4f] gap-10 pt-10">
        <div className="flex flex-col gap-1 items-center justify-center">
          <div className=" text-2xl md:text-[42px] font-berthold text-[#08afb2] ">
            We hope you enjoy our products
          </div>
          <div className="text-4xl md:text-5xl font-berthold text-center md:text-start text-white uppercase">
            as much as we enjoy making it for you!
          </div>
        </div>
        <iframe
          width="1100"
          height="618"
          src="https://www.youtube.com/embed/EMlWtS79bu4?si=RdN5-ZaICCQXyaPT"
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          // referrerpolicy="no-referrer-when-downgrade" // Fix the referrerpolicy value
          // allowfullscreen
        ></iframe>
      </div>

      <div className="w-full  ">
        <img className="w-full   " src={aboutbeyond} alt="" />
      </div>
      <div className="w-full relative ">
        <img className="w-full   " src={aboutstep} alt="" />
        <div className=" absolute bottom-[37%] left-[44%]">
          <img className="w-14 2xl:w-[180px]" src={`${IMAGE_SERVER_URL_DEV}${qrimg}`} alt="" />
        </div>
      </div>
    </div>
  );
}



export default PageAboutUs;
