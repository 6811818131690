import React, { ChangeEvent, FC, useState } from "react";
import facebookSvg from "images/Facebook.svg";
import twitterSvg from "images/Twitter.svg";
import googleSvg from "images/Google.svg";
import { Helmet } from "react-helmet";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import pageBottom2 from "images/pagebottom2.png";
import { Link, useHistory, useLocation } from "react-router-dom";
import { RegisterUser, signUpVerification } from "Services/API/Post";
import { upperFirst, lowerCase } from "lodash";
import toast from "react-hot-toast";
import { Transition } from "@headlessui/react";
import { getSignUpVerification } from "Services/API/Get";
import { addToast } from "shared/Toast";
import LoadingSpinner from "shared/LoadingSpinner";
import newbackground from "images/newbackground.png";
import register from "images/register.png";
import pagetop from "images/page-top.png";
import { avatarImgs } from "contains/fakeData";

import pageBottom from "images/pagebottom.png";
import Checkbox from "shared/Checkbox/Checkbox";
import Label from "components/Label/Label";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { SERVER_URL_DEV } from "Constant/System";

export interface PageSRegisterFirstProps {
  className?: string;
}

const loginSocials = [
  {
    name: "Continue with Facebook",
    href: "#",
    icon: facebookSvg
  },
  {
    name: "Continue with Twitter",
    href: "#",
    icon: twitterSvg
  },
  {
    name: "Continue with Google",
    href: "#",
    icon: googleSvg
  }
];

const PageRegisterFirst: FC<PageSRegisterFirstProps> = ({ className = "" }) => {
  const history = useHistory();

  const [username, setusername] = useState("");
  const [lpass, setlpass] = useState("");
  const [aKey, setaKey] = useState("login");

  const [errUniqueId, seterrUniqueId] = useState(false);
  const [errcompanycode, seterrcompanycode] = useState(false);
  const [errUsername, seterrerrUsername] = useState("");
  const [errLpass, seterrerrLpass] = useState("");

  const [firstName, setfirstName] = useState("");
  const [lastName, setlasttName] = useState("");
  const [mobile, setmobile] = useState("");
  const [email, setemail] = useState("");
  const [premail, setpremail] = useState("");
  const [lopen, setlopen] = React.useState(false);
  const [uniqueId, setUniqueId] = useState("");
  const [employee, setEmployee] = useState("");
  const [company, setCompany] = useState("");
  const [companyCode, setCompanyCode] = useState("");
  const [policycheck, setpolicycheck] = useState(false);

  const [password, setPassword] = useState("");
  const [repassword, setrePassword] = useState("");
  const [idisable, setidisable] = useState(true);
  const [errfirstName, seterrfirstName] = useState(false);
  const [errlastName, seterrlasttName] = useState(false);
  const [errmobile, seterrmobile] = useState("");
  const [erremail, seterremail] = useState(false);
  const [erremplyee, seterremployee] = useState(false);
  const [errcompany, seterrcompany] = useState(false);
  const [errcompanyCode, seterrcompanyCode] = useState(false);
  const [erruniqueid, seterruniqueid] = useState(false);
  const [errpolicycheck, seterrpolicycheck] = useState(false);

  const [errPass, seterrPass] = useState(false);
  const [errRePass, seterrRePass] = useState("");

  const useQuery = () => {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  };

  let query = useQuery();

  React.useEffect(() => {
    getVerification();
  }, []);

  const getVerification = async (e?: any) => {
    let token = query.get("token");
    if (token !== null) {
      setaKey("register");
      setlopen(true);
      // setProgress(true);
      getSignUpVerification(token)
        .then((res) => {
          RegisterUser(JSON.parse(res.data[0].data))
            .then((res) => {
              setlopen(false);
              addToast(`Registration Successful `, "success");
              history.push("/login");
              setaKey("login");
              // setuserId(res.data[0]);
              // setOpenToast(true);
              // setColor(COLORS.Primary);
              // setAlertClass("success");
              // setMsg("Register Successful");
              // setTimeout(() => {
              //   setlopen(false);
              //   //  setOpen(true);
              //   history.push("/login-register");
              //   // window.location.reload();
              //   // setProgress(false);
              // }, 2000);

              // history.push("/");
              // window.location.reload();
            })
            .catch((error) => {
              setlopen(false);
              addToast(error.response.data.message, "warning");

              // console.log("Error", error.response);
              // setProgress(false);
              // setOpenToast(true);
              // setColor(COLORS.Orange);
              // setAlertClass("error");
              // setMsg(error.response.data.message);
            });
        })
        .catch((error) => {
          setlopen(false);
          console.log("Error", error.response);
          addToast(error.response.data.message, "warning");
          // setProgress(false);
          // setOpenToast(true);
          // setColor(COLORS.Orange);
          // setAlertClass("error");
          // setMsg(error.response.data.message);
        });
    }
  };

  const sendVerification = async (e: any) => {
    e.preventDefault();
    const phoneRegex =
      /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;

    e.preventDefault();
    console.log(firstName);
    if (firstName === "" || firstName === null) {
      seterrfirstName(true);
    }
    if (lastName === "" || lastName === null) {
      seterrlasttName(true);
    }
    if (email === "") {
      seterremail(true);
    }
    if (employee === "") {
      seterremployee(true);
    }
    if (policycheck === false) {
      seterrpolicycheck(true);
    }

    // if (company === "") {
    //   seterrcompany(true);
    // }
    if (companyCode === "") {
      seterrcompanyCode(true);
    }
    if (uniqueId === "") {
      seterruniqueid(true);
    }

    if (password === "") {
      seterrPass(true);
    }

    // if (homeNo === "") {
    //   seterrhomeNo(true);
    // }
    // if (mobile === "") {
    //   seterrmobile(true);
    // }
    // if (password !== "") {
    //   //   seterrPass(true)
    //   if (repassword === "") {
    //     seterrRePass("This Field is Requied");
    //   } else {
    //     seterrRePass("");
    //   }
    // }

    if (!phoneRegex.test(mobile)) {
      if (mobile === "") {
        seterrmobile("This Field is Requied");
      } else {
        seterrmobile("Please Enter Valid number");
      }
    }

    let obj = {
      data: {
        unique_id: uniqueId,
        password: password,
        companycode: companyCode,
        firstName: upperFirst(lowerCase(firstName)),
        lastName: upperFirst(lowerCase(lastName)),
        mobileNumber: mobile,
        email: email,
        employee: employee
      }
    };

    if (
      uniqueId !== "" &&
      password !== "" &&
      companyCode !== "" &&
      firstName !== "" &&
      lastName !== "" &&
      email !== "" &&
      mobile !== "" &&
      employee !== "" &&
      policycheck !== false &&
      // (password !== "" ? repassword !== "" : firstName !== "") &&
      phoneRegex.test(mobile)
    ) {
      setlopen(true);
      signUpVerification(obj)
        .then((res) => {
          setlopen(false);
          addToast(
            `Thank you for registering with the Silvermill Coporate Welfare Program. We will let you know once your registration request is approved. `,
            "success"
          );
          setTimeout(() => {
            history.push("/login");
          }, 3000);
        })
        .catch((error) => {
          setlopen(false);
          console.log("Error", error.response);
          addToast(error.response.data.message, "warning");
        });
    } else {
      // scrollElement();
    }
  };

  const [image, setImage] = useState("");
  const [uimage, seutImage] = useState("");

  const [isAccountOpen, setIsAccountOpen] = useState(true);
  const onChangeAvatar = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];

    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {
        const baseURL = reader.result;
        if (typeof baseURL === "string") {
          console.log("img>>>", baseURL);
          setImage(baseURL); // Assuming setImage is defined elsewhere
        }
      };
    }
  };

  return (
    <div className={`nc-PageSignUp  ${className}`} data-nc-id="PageSignUp">
      <Helmet>
        <title>Register || Silvermill</title>
        <meta
          name="description"
          content="Welcome to Oz Cleaning Supplies, your premier destination for top-quality cleaning
    supplies in Dandenong!"
        />

        <meta name="keywords" content="Cleaning supplies Dandenong" />
        <link rel="canonical" href={"/register/"} />

        <script type="application/ld+json">{`
  {
  "@context" : "http://schema.org",
  "@type" : "LocalBusiness",
  "name": "Oz Cleaning Supplies",
  "image" : [ "https://backendozecomadmin.ozcleaningsupplies.com.au/uploads/logo/1670255682167.jpeg", "https://www.ozcleaningsupplies.com.au/" ],
  "telephone": "+61 0397940654",
  "email": "info@ozcleaningsupplies.com.au",
  "address": {
    "@type": "PostalAddress",
    "streetAddress" : "167 Cheltenham Road",
    "addressLocality": "Dandenong",
    "addressRegion" : "VIC ",
    "addressCountry": "Australia",
    "postalCode" : "3175"
  },
  "url": "https://www.ozcleaningsupplies.com.au/signup"
}
    `}</script>
      </Helmet>
      <LoadingSpinner open={lopen} />
      <div className=" mb-24 lg:mb-16 font-berthold">
        <div className="xl:w-full   ">
          <img className=" xl:w-full   " src={register} alt="" />
        </div>
        <div className="xl:w-full   ">
          <img className=" xl:w-full   " src={pagetop} alt="" />
        </div>
        <div className="max-w-4xl mx-auto pt-14 sm:pt-26 pb-24 lg:pb-32">
          <div className="flex flex-col bg-[#f0f0f0] rounded-lg p-10">
            <div
              className="w-full flex justify-between pb-6 p-5 px-10 border-b-[1px] border-white"
              // onClick={toggleAccount}
            >
              <span className="text-[28px] text-[#010a4f]">Register</span>
              <span>
                <ChevronDownIcon
                  className="ml-1 -mr-1 h-6 w-6 text-slate-700"
                  aria-hidden="true"
                />
              </span>
            </div>

            <div
              className={`w-full flex flex-wrap justify-between  px-10 transition-max-height gap-6 duration-500 ease-in-out overflow-hidden ${
                isAccountOpen ? "max-h-[1000px] p-5" : "max-h-0"
              }`}
            >
              <div className=" md:w-[48%] w-full flex md:hidden justify-end items-center w-full">
                <div className=" text-sm font-semibold mr-2">
                  Upload your photo
                </div>
                <div className="relative rounded-full overflow-hidden flex">
                  {image !== "" ? (
                    <img
                      src={image}
                      alt=""
                      className="w-24 h-24 rounded-full object-cover z-0"
                    />
                  ) : uimage !== "" ? (
                    <img
                      alt=""
                      className="w-24 h-24 rounded-full object-cover z-0"
                      src={`${SERVER_URL_DEV}${uimage}`}
                    />
                  ) : (
                    <img
                      src={avatarImgs[2]}
                      alt=""
                      className="w-24 h-24 rounded-full object-cover z-0"
                    />
                  )}

                  <div className="absolute inset-0 bg-black bg-opacity-60 flex flex-col items-center justify-center text-neutral-50 cursor-pointer">
                    <svg
                      width="30"
                      height="30"
                      viewBox="0 0 30 30"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M17.5 5H7.5C6.83696 5 6.20107 5.26339 5.73223 5.73223C5.26339 6.20107 5 6.83696 5 7.5V20M5 20V22.5C5 23.163 5.26339 23.7989 5.73223 24.2678C6.20107 24.7366 6.83696 25 7.5 25H22.5C23.163 25 23.7989 24.7366 24.2678 24.2678C24.7366 23.7989 25 23.163 25 22.5V17.5M5 20L10.7325 14.2675C11.2013 13.7988 11.8371 13.5355 12.5 13.5355C13.1629 13.5355 13.7987 13.7988 14.2675 14.2675L17.5 17.5M25 12.5V17.5M25 17.5L23.0175 15.5175C22.5487 15.0488 21.9129 14.7855 21.25 14.7855C20.5871 14.7855 19.9513 15.0488 19.4825 15.5175L17.5 17.5M17.5 17.5L20 20M22.5 5H27.5M25 2.5V7.5M17.5 10H17.5125"
                        stroke="currentColor"
                        strokeWidth={1.5}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>

                    {/* <span className="mt-1 text-[10px]">Change Image</span> */}
                  </div>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={onChangeAvatar}
                    className="absolute inset-0 opacity-0 cursor-pointer"
                  />
                </div>
              </div>

              <div className=" md:w-[48%] w-full">
                <Label className="text-[#010a4f] text-[20px] tracking-wide uppercase   ">
              User Name
                </Label>
                <Input
                  className="mt-1.5 font-GlacialIndifference text-[17px]"
                  placeholder="User Name"
                  onChange={(e) => {
                    setUniqueId(e.target.value);
                    seterruniqueid(false);
                  }}
                  value={uniqueId}
                />
                {erruniqueid && (
                  <div className="text-red-600 text-xs text-right mt-2">
                    This Field is Requied
                  </div>
                )}
              </div>

              {/* <div className=" md:w-[48%] w-full hidden md:flex justify-end items-center w-full">
                <div className=" text-sm font-semibold mr-2">
                  Upload your photo
                </div>
                <div className="relative rounded-full overflow-hidden flex">
                  {image !== "" ? (
                    <img
                      src={image}
                      alt=""
                      className="w-24 h-24 rounded-full object-cover z-0"
                    />
                  ) : uimage !== "" ? (
                    <img
                      alt=""
                      className="w-24 h-24 rounded-full object-cover z-0"
                      src={`${SERVER_URL}${uimage}`}
                    />
                  ) : (
                    <img
                      src={avatarImgs[2]}
                      alt=""
                      className="w-24 h-24 rounded-full object-cover z-0"
                    />
                  )}

                  <div className="absolute inset-0 bg-black bg-opacity-60 flex flex-col items-center justify-center text-neutral-50 cursor-pointer">
                    <svg
                      width="30"
                      height="30"
                      viewBox="0 0 30 30"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M17.5 5H7.5C6.83696 5 6.20107 5.26339 5.73223 5.73223C5.26339 6.20107 5 6.83696 5 7.5V20M5 20V22.5C5 23.163 5.26339 23.7989 5.73223 24.2678C6.20107 24.7366 6.83696 25 7.5 25H22.5C23.163 25 23.7989 24.7366 24.2678 24.2678C24.7366 23.7989 25 23.163 25 22.5V17.5M5 20L10.7325 14.2675C11.2013 13.7988 11.8371 13.5355 12.5 13.5355C13.1629 13.5355 13.7987 13.7988 14.2675 14.2675L17.5 17.5M25 12.5V17.5M25 17.5L23.0175 15.5175C22.5487 15.0488 21.9129 14.7855 21.25 14.7855C20.5871 14.7855 19.9513 15.0488 19.4825 15.5175L17.5 17.5M17.5 17.5L20 20M22.5 5H27.5M25 2.5V7.5M17.5 10H17.5125"
                        stroke="currentColor"
                        strokeWidth={1.5}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>

                        </div>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={onChangeAvatar}
                    className="absolute inset-0 opacity-0 cursor-pointer"
                  />
                </div>
              </div> */}
       <div className=" md:w-[48%] w-full">
                <Label className="text-[#010a4f] text-[20px] tracking-wide uppercase ">
                Password
                </Label>

                <Input
                 type="password"
                  className="mt-1.5 font-GlacialIndifference text-[17px]"
                  placeholder="Password"
                  onChange={(e) => {
                    setPassword(e.target.value);
                    seterrPass(false);
                  }}
                  value={password}
                />

{errPass && (
                <div className="text-red-600 text-xs text-right mt-2">
                  This Field is Requied
                </div>
              )}
              </div>

              <div className=" md:w-[48%] w-full">
                <Label className="text-[#010a4f] text-[20px] tracking-wide uppercase ">
                  First Name
                </Label>
                <Input
                  className="mt-1.5 font-GlacialIndifference text-[17px]"
                  placeholder="First Name"
                  onChange={(e) => {
                    setfirstName(e.target.value);
                    seterrfirstName(false);
                  }}
                  value={firstName}
                />
                {errfirstName && (
                  <div className="text-red-600 text-xs text-right mt-2">
                    This Field is Requied
                  </div>
                )}
              </div>

              <div className=" md:w-[48%] w-full">
                <Label className="text-[#010a4f] text-[20px] tracking-wide  uppercase">
                  Last Name
                </Label>
                <Input
                  className="mt-1.5 font-GlacialIndifference text-[17px]"
                  placeholder="Last Name"
                  onChange={(e) => {
                    setlasttName(e.target.value);
                    seterrlasttName(false);
                  }}
                  value={lastName}
                />
                {errlastName && (
                  <div className="text-red-600 text-xs text-right mt-2">
                    This Field is Requied
                  </div>
                )}
              </div>


              <div className=" md:w-[48%] w-full">
                <Label className="text-[#010a4f] text-[20px] tracking-wide uppercase ">
                  Contact Number
                </Label>

                <Input
                  className="mt-1.5 font-GlacialIndifference text-[17px]"
                  // defaultValue="003 888 232"
                  placeholder="Enter Mobile"
                  onChange={(e) => {
                    setmobile(e.target.value);
                    seterrmobile("");
                  }}
                  value={mobile}
                />

                {errmobile && (
                  <div className="text-red-600 text-xs text-right mt-2">
                    {errmobile}
                  </div>
                )}
              </div>

              <div className=" md:w-[48%] w-full">
                <Label className="text-[#010a4f] text-[20px] tracking-wide uppercase ">
                  Email
                </Label>

                <Input
                  className="mt-1.5 font-GlacialIndifference text-[17px]"
                  placeholder="example@email.com"
                  onChange={(e) => {
                    setemail(e.target.value);
                    seterremail(false);
                  }}
                  value={email}
                />

                {erremail && (
                  <div className="text-red-600 text-xs text-right mt-2">
                    This Field is Requied
                  </div>
                )}
              </div>
       
              {/* <div className=" md:w-[48%] w-full">
                <Label className="text-[#010a4f] text-[20px] tracking-wide  ">
                  Employer
                </Label>
                <Input
                  className="mt-1.5 font-GlacialIndifference text-[17px]"
                  placeholder="Company"
                  onChange={(e) => {
                    setCompany(e.target.value);
                    seterrcompany(false);
                  }}
                  value={company}
                />
                {errcompany && (
                  <div className="text-red-600 text-xs text-right mt-2">
                    This Field is Requied
                  </div>
                )}
              </div> */}
              <div className=" md:w-[48%] w-full">
                <Label className="text-[#010a4f] text-[20px] tracking-wide uppercase  ">
                  Employer Code
                </Label>
                <Input
                  className="mt-1.5 font-GlacialIndifference text-[17px]"
                  placeholder="Employer Code"
                  onChange={(e) => {
                    setCompanyCode(e.target.value);
                    seterrcompanyCode(false);
                  }}
                  value={companyCode}
                />
                {errcompanyCode && (
                  <div className="text-red-600 text-xs text-right mt-2">
                    This Field is Requied
                  </div>
                )}
              </div>
              <div className=" md:w-[48%] w-full">
                <Label className="text-[#010a4f] text-[20px] tracking-wide uppercase ">
                  Employee Number
                </Label>
                <Input
                  className="mt-1.5 font-GlacialIndifference text-[17px]"
                  placeholder="Employee Number"
                  onChange={(e) => {
                    setEmployee(e.target.value);
                    seterremployee(false);
                  }}
                  value={employee}
                />
                {erremplyee && (
                  <div className="text-red-600 text-xs text-right mt-2">
                    This Field is Requied
                  </div>
                )}
              </div>

              <div className=" md:w-[48%] hidden md:block w-full"></div>

              <div className=" flex w-full flex-col font-GlacialIndifference gap-3 text-[#010a4f] text-base mt-3 ">
                <p>
                  The Silvermill Corporate Discount Program is an exclusive
                  offer provided to our Corporate Partners who have joined us to
                  provide the Program benefits to their employees. All products
                  ordered will be delivered to the venue of our Corporate
                  Partners. ​
                </p>
                <p className="mb-5">
                  Once you register, we will contact your employer to confirm
                  that you are an employee of our Corporate Partners. Once this
                  is confirmed, your account will be activated and you will be
                  able to order our discounted products!
                </p>
                <div className=" flex gap-1 items-center">
                  <Checkbox
                    name={""}
                    // label={
                    //   " By registering, you understand and consent to the above. "
                    // }
                    // checked={selectedCat === ""}
                    sizeClassName="w-5 h-5"
                    labelClassName="font-GlacialIndifference text-[#010a4f] text-base font-normal"
                    onChange={(checked) => {
                      setpolicycheck(checked);
                    }}
                    // onChange={(checked) => handleChangeCategories(checked, item.mainId)}
                  />
                  <span className="font-GlacialIndifference text-[#010a4f] text-base font-normal">
                    By registering, you agree to our{" "}
                    <Link
                      className=" underline"
                      target="_blank"
                      to="/privacy-policies"
                    >
                      Privacy Policy
                    </Link>
                    ,{" "}
                    <Link
                      className=" underline"
                      target="_blank"
                      to="/conditions"
                    >
                      Terms & Conditions
                    </Link>{" "}
                    and{" "}
                    <Link
                      className=" underline"
                      target="_blank"
                      to="/return-policies"
                    >
                      Return Policy
                    </Link>
                    .
                  </span>
                </div>

                {errpolicycheck && (
                  <div className="text-red-600 text-xs text-left ml-5">
                    This Field is Requied
                  </div>
                )}
              </div>

              <div className=" flex justify-center w-full">
                <div className=" md:w-[48%] w-full">
                  <button
                    className="w-full  mt-1.5 ttnc-ButtonPrimary disabled:bg-opacity-90 bg-[#20254a] dark:bg-slate-100 hover:bg-slate-100 hover:text-[#20254a] border-[#20254a] border-[1px] text-slate-50 dark:text-slate-800 rounded-md shadow-xl font-berthold py-3 "
                    onClick={sendVerification}
                  >
                    <span className=" text-xl">Create Account</span>
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* <div
            className="flex flex-col bg-[#f1f1f0] mt-8  rounded-lg"
            onClick={togglePassword}
          >
            <div className="w-full flex justify-between  pb-6 p-5 px-10 border-b-[1px] border-white">
              <span className="text-base">2. Change Password</span>
              <span>
                <ChevronDownIcon
                  className="ml-1 -mr-1 h-6 w-6 text-slate-700"
                  aria-hidden="true"
                />
              </span>
            </div>
            {isPasswordOpen && (
              <div className="w-full flex flex-col p-5 px-10  mt-10 md:mt-0">
                <div className="w-full flex justify-between   ">
                  <div className="md:w-[48%] w-full">
                    <Label className="text-[#545454]">Current Password</Label>
                    <Input
                        className="mt-1.5 font-GlacialIndifference text-base"
                      placeholder="ACB-XXXX"
                      onChange={(e) => {
                        setfirstName(e.target.value);
                        seterrfirstName(false);
                      }}
                      value={firstName}
                    />
                    {errfirstName && (
                      <div className="text-red-600 text-xs text-right mt-2">
                        This Field is Requied
                      </div>
                    )}
                  </div>
                  <div className="md:w-[48%] w-full">
                    <Label className="text-[#545454]">New Password</Label>
                    <Input
                        className="mt-1.5 font-GlacialIndifference text-base"
                      placeholder="ACB-XXXX"
                      onChange={(e) => {
                        setfirstName(e.target.value);
                        seterrfirstName(false);
                      }}
                      value={firstName}
                    />
                    {errfirstName && (
                      <div className="text-red-600 text-xs text-right mt-2">
                        This Field is Requied
                      </div>
                    )}
                  </div>
                </div>

                <div className="w-full flex justify-end">
                  <div className="pt-2 pb-5 md:w-[48%] w-full mt-5">
                    <ButtonPrimary className="w-full" onClick={onSubmit}>
                      Update Password
                    </ButtonPrimary>
                  </div>
                </div>
              </div>
            )}
          </div> */}
        </div>
      </div>
      <div className="w-full flex  flex-col">
        <img src={pageBottom} alt="" />
      </div>
    </div>
  );
};

export default PageRegisterFirst;
