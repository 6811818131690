import React, { FC } from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";

export interface PageReturnPolicyProps {
  className?: string;
}

const PageReturnPolicy: FC<PageReturnPolicyProps> = ({ className = "" }) => {
  const location = useLocation();
  const path = location.pathname;

  return (
    <div
      className={`nc-PageReturnPolicy overflow-hidden relative ${className}`}
      data-nc-id="PageReturnPolicy"
    >
      <Helmet>
        <title>Return Policy</title>
        <meta
          name="description"
          content="Learn about our return policy for defective or damaged products at the Silvermill Corporate Discount Program website."
        />
        <meta name="keywords" content="Return Policy, Silvermill Corporate Discount Program" />
        <link rel="canonical" href={path + "/"} />
      </Helmet>

      <div className="container py-16 lg:py-28 space-y-16 lg:space-y-14">
        <div className="flex items-center justify-items-center justify-center grid grid-cols-1 lg:grid-cols-1 gap-10">
          <div className="">
            <h2 className="text-lg font-semibold text-neutral-900 md:text-2xl xl:text-2xl dark:text-neutral-100">
              RETURNS POLICY
            </h2>
            <div className="text-left mt-6">
              <p className="block text-sm xl:text-base text-neutral-6000 dark:text-neutral-400 mt-3">
                Thank you for shopping at the Silvermill Corporate Discount
                Program website (www.cdp.silvermillgroup.lk). We value your
                satisfaction and strive to provide you with the best online
                shopping experience possible.
              </p>

              {/* Returns & Replacements */}
              <h3 className="text-base font-semibold text-neutral-900 md:text-xl xl:text-xl dark:text-neutral-100 mt-8">
                Returns & Replacements
              </h3>
              <p className="block text-sm xl:text-base text-neutral-6000 dark:text-neutral-400 mt-3">
                We accept returns only in the event there is a product defect or
                pack damage upon delivery. Requests for such returns must be
                communicated to Silvermill via our phone number (see Contact Us
                section below) within 21 days of the product being delivered to
                the Corporate Partner delivery location. Please note that we
                will not be able to process return requests that do not meet the
                criteria above.
              </p>

              {/* Return Shipping */}
              <h3 className="text-base font-semibold text-neutral-900 md:text-xl xl:text-xl dark:text-neutral-100 mt-8">
                Return Shipping
              </h3>
              <p className="block text-sm xl:text-base text-neutral-6000 dark:text-neutral-400 mt-3">
                Within 3 working days of receiving your return request, a
                Silvermill representative will meet you at the Corporate Partner
                delivery location. Once you show the purchase invoice and
                defective product, the Silvermill representative will accept the
                defective product and provide you with a replacement product.
                Please note that Silvermill will not issue refunds.
              </p>

              <h3 className="text-base font-semibold text-neutral-900 md:text-xl xl:text-xl dark:text-neutral-100 mt-8">
                Return Shipping Costs
              </h3>
              <p className="block text-sm xl:text-base text-neutral-6000 dark:text-neutral-400 mt-3">
                Silvermill will cover the costs of accepting any defective
                products and providing you with a replacement product as long as
                the return request meets the criteria listed above.
              </p>

              {/* Processing Time */}
              <h3 className="text-base font-semibold text-neutral-900 md:text-xl xl:text-xl dark:text-neutral-100 mt-8">
                Processing Time
              </h3>
              <p className="block text-sm xl:text-base text-neutral-6000 dark:text-neutral-400 mt-3">
                Returns and replacements will be processed within 3 working days
                after we receive your return request via our contact number.
              </p>

              {/* Changes to This Return Policy */}
              <h3 className="text-base font-semibold text-neutral-900 md:text-xl xl:text-xl dark:text-neutral-100 mt-8">
                Changes to This Return Policy
              </h3>
              <p className="block text-sm xl:text-base text-neutral-6000 dark:text-neutral-400 mt-3">
                We reserve the right to update or modify this Return Policy at
                any time without prior notice. We encourage you to review this
                Return Policy periodically to stay informed.
              </p>

              {/* Contact Us */}
              <h3 className="text-base font-semibold text-neutral-900 md:text-xl xl:text-xl dark:text-neutral-100 mt-8">
                Contact Us
              </h3>
              <p className="block text-sm xl:text-base text-neutral-6000 dark:text-neutral-400 mt-3">
                Please contact us at{" "}
                <a
                  href="tel:+94704767523"
                  className="text-blue-600 hover:underline"
                >
                  +94 704 767 523
                </a>{" "}
                with your return and replacement requests.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageReturnPolicy;
