import React, { useEffect } from "react";
import { CurrentUser, getCart,getCartList,getMemberCart, getWishlistList } from "../Services/API/Get";
import _ from "lodash";

const UserWishList = async () => {
  const myCountOfCart = async () => {
    let Count = [];

    await getWishlistList({limit : Number.MAX_SAFE_INTEGER})
    .then((res) => {
      let m = 0;
      const abc = res.data?.map((e) => {
        m = m + e.qty * e.discountedprice;
      });
      Count = res.data;
     
      // this.setState({
      //   array: res.data,
      //   total: m,
      // });
    })
    .catch((err) => {
      Count = [];
     
     
    });
    return Count;


  };

  return await myCountOfCart();
};

export default UserWishList;
