// import rightImg from "images/hero-right1.png";
import rightImg from "images/about/Maps.png";
import React, { FC } from "react";
import SectionFounder, { People } from "./SectionFounder";
import SectionStatistic from "./SectionStatistic";
import { Helmet } from "react-helmet";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionHero from "./SectionHero";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import SectionPromo3 from "components/SectionPromo3";
import NcImage from "shared/NcImage/NcImage";
import { useLocation } from "react-router-dom";

export interface PagePrivacyProps {
  className?: string;
}

const FOUNDER_DEMO: People[] = [
  {
    id: "1",
    name: `Niamh O'Shea`,
    job: "Co-founder and Chief Executive",
    avatar:
      "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=400&q=80"
  },
  {
    id: "4",
    name: `Danien Jame`,
    job: "Co-founder and Chief Executive",
    avatar:
      "https://images.unsplash.com/photo-1568602471122-7832951cc4c5?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=400&q=80"
  },
  {
    id: "3",
    name: `Orla Dwyer`,
    job: "Co-founder, Chairman",
    avatar:
      "https://images.unsplash.com/photo-1560365163-3e8d64e762ef?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=400&q=80"
  },
  {
    id: "2",
    name: `Dara Frazier`,
    job: "Co-Founder, Chief Strategy Officer",
    avatar:
      "https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=400&q=80"
  }
];

const PagePrivacy: FC<PagePrivacyProps> = ({ className = "" }) => {
  const location = useLocation();
  const path = location.pathname;
  return (
    <div
      className={`nc-PagePrivacy overflow-hidden relative ${className}`}
      data-nc-id="PagePrivacy"
    >
      <Helmet>
        <title>Privacy Policy</title>
        <meta
          name="description"
          content="Welcome to Oz Cleaning Supplies, your premier destination for top-quality cleaning supplies in Dandenong!"
        />
        <meta name="keywords" content="Cleaning supplies Dandenong" />
        <link rel="canonical" href={path + "/"} />
      </Helmet>

      <div className="container py-16 lg:py-28 space-y-16 lg:space-y-14">
        <div className="flex items-center justify-items-center justify-center grid grid-cols-1 lg:grid-cols-1 gap-10">
          <div className="">
            <h2 className="text-lg font-semibold text-neutral-900 md:text-2xl xl:text-2xl dark:text-neutral-100">
              PRIVACY POLICY
            </h2>
            <div className="text-left mt-6">
              <h3 className="text-base font-semibold text-neutral-900 md:text-xl xl:text-xl dark:text-neutral-100 mt-4">
                Effective Date: 1st November 2024
              </h3>
              <p className="block text-sm xl:text-base text-neutral-6000 dark:text-neutral-400 mt-3">
                At the Silvermill Corporate Discount Program 
                (www.cdp.silvermillgroup.lk), we are committed to protecting the
                privacy and security of our customers' personal information.
                This Privacy Policy outlines how we collect, use, and safeguard
                your information when you visit or make a purchase on our
                website. By accessing or using our website, you consent to the
                practices described in this policy.
              </p>

              {/* Information We Collect */}
              <h3 className="text-base font-semibold text-neutral-900 md:text-xl xl:text-xl dark:text-neutral-100 mt-8">
                1. Information We Collect
              </h3>
              <ul className="list-disc text-sm xl:text-base text-neutral-6000 dark:text-neutral-400 mt-3 ml-5">
                <li>
                  <strong>Personal Identification Information:</strong>{" "}
                  Information you provide voluntarily during registration,
                  checkout, or account setup, such as employee name, employee
                  number, email address, employer, and phone number.
                </li>
                <li>
                  <strong>Payment and Billing Information:</strong> Information
                  necessary to process transactions, including payment card
                  details, which are securely processed by third-party payment
                  processors.
                </li>
                <li>
                  <strong>Browsing Information:</strong> Technical data, such as
                  IP address, browser type, device details, and browsing
                  actions, collected automatically through cookies and similar
                  tracking technologies.
                </li>
              </ul>

              {/* Use of Information */}
              <h3 className="text-base font-semibold text-neutral-900 md:text-xl xl:text-xl dark:text-neutral-100 mt-8">
                2. Use of Information
              </h3>
              <ul className="list-disc list-inside text-sm xl:text-base text-neutral-6000 dark:text-neutral-400 mt-3 ml-1">
                <li>
                  Process and fulfill your orders, including product shipment
                  and delivery.
                </li>
                <li>
                  Communicate with you regarding your purchases, provide
                  customer support, and respond to inquiries.
                </li>
                <li>
                  Personalize your shopping experience by presenting relevant
                  product recommendations and promotions.
                </li>
                <li>
                  Enhance and improve our website, products, and services based
                  on customer feedback and browsing behavior.
                </li>
                <li>
                  Detect and prevent fraud, unauthorized activities, or other
                  potential security risks.
                </li>
              </ul>

              {/* Information Sharing */}
              <h3 className="text-base font-semibold text-neutral-900 md:text-xl xl:text-xl dark:text-neutral-100 mt-8">
                3. Information Sharing and Disclosure
              </h3>
              <p className="block text-sm xl:text-base text-neutral-6000 dark:text-neutral-400 mt-3">
                We respect your privacy and do not sell, trade, or otherwise
                transfer your personal information to third parties without your
                consent, except in the following situations:
              </p>
              <ul className="list-disc text-sm xl:text-base text-neutral-6000 dark:text-neutral-400 mt-3 ml-5">
                <li>
                  <strong>Service Providers:</strong> We may share your
                  information with third-party providers that assist us in
                  operating our website, processing payments, and fulfilling
                  orders. These parties are contractually bound to handle your
                  information securely and only for specified purposes.
                </li>
                <li>
                  <strong>Legal Obligations:</strong> We may disclose your
                  information if legally required or if we believe such
                  disclosure is necessary to protect our rights, comply with
                  legal processes, or respond to valid governmental requests.
                </li>
              </ul>

              {/* Data Security */}
              <h3 className="text-base font-semibold text-neutral-900 md:text-xl xl:text-xl dark:text-neutral-100 mt-8">
                4. Data Security
              </h3>
              <p className="block text-sm xl:text-base text-neutral-6000 dark:text-neutral-400 mt-3">
                We use industry-standard security measures, including
                encryption, firewalls, and secure socket layer (SSL) technology,
                to protect your personal information from unauthorized access,
                alteration, disclosure, or destruction. While we strive to
                protect your data, please be aware that no method of
                transmission over the internet or electronic storage is
                completely secure, and we cannot guarantee absolute security.
              </p>

              {/* Cookies and Tracking Technologies */}
              <h3 className="text-base font-semibold text-neutral-900 md:text-xl xl:text-xl dark:text-neutral-100 mt-8">
                5. Cookies and Tracking Technologies
              </h3>
              <p className="block text-sm xl:text-base text-neutral-6000 dark:text-neutral-400 mt-3">
                We use cookies and similar technologies to enhance your browsing
                experience, analyze traffic, and gather data on your preferences
                and interactions with our website. You can adjust your browser
                settings to refuse cookies; however, doing so may limit certain
                features of our website.
              </p>

              {/* Your Rights and Choices */}
              <h3 className="text-base font-semibold text-neutral-900 md:text-xl xl:text-xl dark:text-neutral-100 mt-8">
                6. Your Rights and Choices
              </h3>
              <p className="block text-sm xl:text-base text-neutral-6000 dark:text-neutral-400 mt-3">
                Depending on your location, you may have specific rights
                regarding your personal information, including access,
                correction, or deletion of your data. Please contact us if you
                wish to exercise any of these rights.
              </p>

              {/* Changes to This Privacy Policy */}
              <h3 className="text-base font-semibold text-neutral-900 md:text-xl xl:text-xl dark:text-neutral-100 mt-8">
                7. Changes to This Privacy Policy
              </h3>
              <p className="block text-sm xl:text-base text-neutral-6000 dark:text-neutral-400 mt-3">
                We reserve the right to update or modify this Privacy Policy at
                any time. Changes will be posted on this page with an updated
                "Effective Date." We encourage you to review this Privacy Policy
                periodically to stay informed about our data practices.
              </p>

              {/* Contact Us */}
              <h3 className="text-base font-semibold text-neutral-900 md:text-xl xl:text-xl dark:text-neutral-100 mt-8">
                8. Contact Us
              </h3>
              <p className="block text-sm xl:text-base text-neutral-6000 dark:text-neutral-400 mt-3">
                If you have questions, concerns, or requests regarding this
                Privacy Policy or the handling of your personal information,
                please contact us at <a href="/contact-us">Contact Us</a>.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PagePrivacy;
