import React, { FC, useContext, useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import type { Swiper as SwiperRef } from "swiper";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import LikeButton from "components/LikeButton";
import AccordionInfo from "./AccordionInfo";
import { StarIcon } from "@heroicons/react/24/solid";
import BagIcon from "components/BagIcon";
import NcInputNumber from "components/NcInputNumber";
import { PRODUCTS } from "data/data";
import {
  NoSymbolIcon,
  ClockIcon,
  SparklesIcon
} from "@heroicons/react/24/outline";
import pageBottom from "images/pagebottom.png";
import IconDiscount from "components/IconDiscount";
import Prices from "components/Prices";
import toast from "react-hot-toast";
import SectionSliderProductCard from "components/SectionSliderProductCard";
import detail1JPG from "images/products/detail1.jpg";
import detail2JPG from "images/products/detail2.jpg";
import detail3JPG from "images/products/detail3.jpg";
import Policy from "./Policy";
import ReviewItem from "components/ReviewItem";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import SectionPromo2 from "components/SectionPromo1";
import ModalViewAllReviews from "./ModalViewAllReviews";
import NotifyAddTocart from "components/NotifyAddTocart";
import ModalPhotos from "./ModalPhotos";

import save from "images/save.png";
import {
  Route,
  Link,
  useParams,
  useHistory,
  useLocation
} from "react-router-dom";
import {
  CurrentUser,
  getDeliveryDateByCart,
  getProductById,
  getProductByName,
  getProductBySizeAndColorId,
  getProductBySizeAndColorNew,
  getProductBySizeAndMaterial,
  getTestimonial
} from "Services/API/Get";
import { IMAGE_SERVER_URL_DEV } from "Constant/System";
import _ from "lodash";
import parse from "html-react-parser";
import LoadingSpinner from "shared/LoadingSpinner";
import jwtDecode from "jwt-decode";
import { addToCard, addToWishlist } from "Services/API/Post";
import CartCount from "Constant/CartCount";
import CartContext from "Context/CartContext";
import { addToast } from "shared/Toast";
import UserWishList from "Constant/WishList";
import { Transition } from "@headlessui/react";
import { DeleteWishlist } from "Services/API/Delete";
import WishContext from "Context/WishContext";
import { upperFirst, lowerCase } from "lodash";
import { Helmet } from "react-helmet";

import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import { FreeMode, Navigation, Thumbs, Pagination } from "swiper/modules";

export interface ProductDetailPageProps {
  className?: string;
}

const ProductDetailPage: FC<ProductDetailPageProps> = ({ className = "" }) => {
  const location = useLocation();
  const path = location.pathname;

  const history = useHistory();
  const { wish, setWish } = useContext(WishContext);
  const [reviews, setreviews] = useState([]);

  const { cart, setCart } = useContext(CartContext);

  const { sizes, status, allOfSizes } = PRODUCTS[0];
  const { variants } = PRODUCTS[1];
  const LIST_IMAGES_DEMO = [detail1JPG, detail2JPG, detail3JPG];
  const [thumbsSwiper, setThumbsSwiper] = React.useState<SwiperRef | null>(
    null
  );
  const [variantActive, setVariantActive] = React.useState(0);
  const [sizeSelected, setSizeSelected] = React.useState("");
  const [qualitySelected, setQualitySelected] = React.useState(1);
  const [isOpenModalViewAllReviews, setIsOpenModalViewAllReviews] =
    useState(false);

  const [isOpen, setIsOpen] = useState(false);
  const [openFocusIndex, setOpenFocusIndex] = useState(0);

  const [unitPrice, setunitPrice] = useState(0);
  const [productStock, setProductStock] = useState(
    // product.variation ? product.variation[0].size[0].stock : product.stock
    // product.availableStocks ? product.availableStocks : 0
    0
  );
  const [selectedProductId, setselectedProductId] = useState(0);
  const [selectedProductColor, setSelectedProductColor] = useState("");
  const [selectedProductPrice, setselectedProductPrice] = useState(0);
  const [selectedProduct, setselectedProduct] = React.useState<any | null>("");

  const [prodImages, setprodImages] = React.useState<any | null>([]);

  const getTestimonials = async () => {
    try {
      await getTestimonial()
        .then((response: any) => {
          console.log(">>>>>>>>>>>>>>>>>>", response?.data);
          setreviews(response.data);
        })
        .catch(() => {
          console.log("T&C ");
        });
    } catch (error) {
      console.log("T&C ", error);
    }
  };

  useEffect(() => {
    getTestimonials();
  }, []);
  let proId: any = null;

  proId = useParams();

  proId = proId.id;

  const [sProduct, setsProduct] = React.useState<any | null>("");
  const [pInfo, setpInfo] = React.useState<any | null>("");
  const [lopen, setlopen] = React.useState(true);

  const getThisProduct = (myyid: any) => {
    let productMyID = "";
    if (myyid === undefined) {
      productMyID = proId;
      if (productMyID === "undefined") {
        // this.props.history.goBack();
        window.scroll(0, 0);
        return;
      }
    } else {
      productMyID = myyid;
    }
    setlopen(true);
    getProductById(productMyID).then((res) => {
      setpInfo(res?.data[0]);
      getProduct(
        res?.data[0]?.productName,
        productMyID,
        res?.data[0].categoryName,
        res?.data[0]
      );
    });
  };

  const getProduct = (name: any, id: string, category: any, info: any) => {
    getProductByName({
      name: info.productId
    })
      .then((res) => {
        setlopen(false);
        let colorsof = [];
        let sizeOf = [];
        let imageOf = [];
        let tumblineOf = "";
        let imageOf2 = [];
        let materialOf = [];
        const mobile = _.uniqBy(res?.data, function (e: any) {
          return e.colour;
        });

        var mobile222 = mobile?.filter(function (el: any) {
          return el.colour !== "";
        });

        const mobile2 = _.uniqBy(res?.data, function (e: any) {
          return e.size;
        });

        var mobile22 = mobile2?.filter(function (el: any) {
          return el.size !== "";
        });

        const mobile3 = _.uniqBy(res?.data, function (e: any) {
          return e.meterial;
        });

        var mobile4 = mobile3?.filter(function (el: any) {
          return el.meterial !== "";
        });

        colorsof = mobile222.map((data: any, index) => {
          return {
            id: `colors${index}`,
            status: index === 0 ? true : false,
            color: `${data?.colour}`.trim().toString(),
            data: data
          };
        });

        sizeOf = mobile22.map((data: any, index) => {
          return {
            id: `size${index}`,
            status: index === 0 ? true : false,
            size: `${data?.size}`.trim().toString(),
            data: data
          };
        });

        materialOf = mobile4.map((data: any, index) => {
          return {
            id: `material${index}`,
            status: index === 0 ? true : false,
            material: `${data?.meterial}`.trim().toString(),
            data: data
          };
        });
        const newArr = res?.data?.map((item: { images: string }) => {
          return {
            images: JSON.parse(item.images)
          };
        });

        imageOf = JSON.parse(res?.data[0].images);
        var imgArr: { original: string; thumbnail: string }[] = [];

        // const imageOf3 =
        //   imageOf.length > 0 &&
        //   imageOf?.map((item2: { path: any }) => {
        //     let obj = {
        //       original: `${IMAGE_SERVER_URL_DEV}${item2.path}`,
        //       thumbnail: `${IMAGE_SERVER_URL_DEV}${item2.path}`
        //     };
        //     imgArr.push(obj);
        //     return {
        //       original: `${IMAGE_SERVER_URL_DEV}${item2.path}`,
        //       thumbnail: `${IMAGE_SERVER_URL_DEV}${item2.path}`
        //     };
        //   });
        // if (imageOf)
        //   tumblineOf = imageOf?.filter(
        //     (item: { thumbnail: boolean }) => item?.thumbnail === true
        //   )[0];
        // // }

        let def = Object.assign({}, info);

        setSizeSelected(sizeOf.length > 0 ? sizeOf[0].size : "none");
        setSelectedProductColor(
          colorsof.length > 0 ? colorsof[0].color : "none"
        );
        setprodImages(imgArr);

        def.variation = colorsof;
        def.sizes = sizeOf;
        def.nImages = imgArr;
        def.productId = proId;
        def.materials = materialOf;
        def.categoryName = category;
        def.thumbnail = tumblineOf;

        setsProduct(def);

        // this.setState(
        //   {
        //     productData: res?.data[0],
        //     color: colorsof,
        //     size: sizeOf,
        //     render: true,
        //     image: imageOf2,
        //     discount: res?.data[0].discount,
        //   },
        //   () => {
        //     this.setState({
        //       showRealtedProduct: true,
        //     });
        //     this.getComments(id);
        //   }
        // );
      })
      .catch((error) => {
        setlopen(false);
        console.log("error", error);
      });
  };

  React.useEffect(() => {
    getThisProduct(undefined);
  }, [proId]);

  const getProductPrice = () => {
    const myColor = sProduct.variation?.filter(
      (item: any) => item?.status === true
    );
    const mySize = sProduct.sizes?.filter((item: any) => item?.status === true);
    console.log(sProduct?.productName);
    // setlopen(true);
    getProductBySizeAndColorId({
      productId: `${sProduct?.productId}`.trim().toString(),
      // color: `${selectedProductColor}`.trim().toString(),
      size: `${sizeSelected}`.trim().toString(),
      colour: `${selectedProductColor}`.trim().toString()
    })
      .then((data) => {
        setlopen(false);
        let currProd = data?.data[0];
        setselectedProduct(data?.data[0]);
        setselectedProductPrice(data?.data[0]?.unitPrice);
        // setselectedDiscountPrice(data?.data[0]?.discountedPrice);
        setProductStock(currProd?.availableStocks);
        setselectedProductId(data?.data[0].id);
        setQualitySelected(1);
        // setpriceInfo(JSON.parse(data?.data[0]?.priceDetails))

        let unitPrice =
          JSON.parse(currProd.priceDetails).length > 0
            ? JSON.parse(currProd.priceDetails)[0].unitPrice
            : 0;
        let discount = currProd.discount;

        setunitPrice(currProd.unitPrice);

        let imageOf = [];

        imageOf = JSON.parse(data?.data[0].images);

        var imgArr: { original: string; thumbnail: string }[] = [];

        const imageOf3 =
          imageOf.length > 0 &&
          imageOf?.map((item2: { path: any }) => {
            let obj = {
              original: `${IMAGE_SERVER_URL_DEV}${item2.path}`,
              thumbnail: `${IMAGE_SERVER_URL_DEV}${item2.path}`
            };
            imgArr.push(obj);
            return {
              original: `${IMAGE_SERVER_URL_DEV}${item2.path}`,
              thumbnail: `${IMAGE_SERVER_URL_DEV}${item2.path}`
            };
          });

        setprodImages(imgArr);

        // setdiscount(currProd.discount);
        // setfinalProductPrice(unitPrice?.toFixed(2));
        // setdiscountedPrice(parseFloat(unitPrice * discount / 100).toFixed(2))
        // setfinalDiscountedPrice(parseFloat(unitPrice * (100 - discount) / 100).toFixed(2))

        // if (data?.data[0]?.categoryName !== "Business Card & Letterhead" && data?.data[0]?.meterial === "Gloss/Matt") {
        //   setQuantityCount(50)
        // } else if (data?.data[0]?.categoryName !== "Business Card & Letterhead" && data?.data[0]?.meterial !== "Gloss/Matt") {
        //   setQuantityCount(25)
        // }
        // this.setState({
        //   unitPrice: data?.data[0]?.unit,
        //   discountedprice: data?.data[0]?.discountedPrice,
        // });
      })
      .catch((error) => {
        setlopen(false);
        console.log("error occur while fetching size", error);
        setProductStock(0);
        setunitPrice(0);
      });
  };

  React.useEffect(() => {
    // if (sizeSelected && selectedProductColor) {
    if (sProduct !== "") {
      getProductPrice();
    }
    // }
    // getCurrentUser();
  }, [sizeSelected, selectedProductColor, sProduct]);

  const handleOpenModal = (index: number) => {
    setIsOpen(true);
    setOpenFocusIndex(index);
  };

  const handleCloseModal = () => setIsOpen(false);

  const getBorderClass = (Bgclass = "") => {
    if (Bgclass.includes("red")) {
      return "border-rose-500";
    }
    if (Bgclass.includes("black")) {
      return "border-slate-900";
    }
    if (Bgclass.includes("gray")) {
      return "border-slate-900";
    }
    if (Bgclass.includes("yellow")) {
      return "border-amber-500";
    }
    if (Bgclass.includes("blue")) {
      return "border-blue-500";
    }
    if (Bgclass.includes("green")) {
      return "border-green-500";
    }
    if (Bgclass.includes("white")) {
      return "border-gray-400";
    }
    if (Bgclass.includes("pink")) {
      return "border-pink-400";
    }
    if (Bgclass.includes("orange")) {
      return "border-orange-500";
    }
    if (Bgclass.includes("brown")) {
      return "border-stone-800";
    }

    return "border-transparent";
  };

  const getBackgrounClass = (Bgclass = "") => {
    if (Bgclass.includes("red")) {
      return "bg-[#ef4444]";
    }
    if (Bgclass.includes("black")) {
      return "bg-[#334155]";
    }
    if (Bgclass.includes("gray")) {
      return "bg-[#9ca3af]";
    }
    if (Bgclass.includes("yellow")) {
      return "bg-[#fcd34d]";
    }
    if (Bgclass.includes("blue")) {
      return "bg-[#2563eb]";
    }
    if (Bgclass.includes("green")) {
      return "bg-[#22c55e]";
    }
    if (Bgclass.includes("white")) {
      return "bg-[#d6d3d1]";
    }
    if (Bgclass.includes("pink")) {
      return "bg-[#FFC0CB]";
    }

    if (Bgclass.includes("orange")) {
      return "bg-[#fb923c]";
    }
    if (Bgclass.includes("brown")) {
      return "bg-[#78350f]";
    }

    return "border-transparent";
  };

  const notifyAddToWish = () => {
    const getToken = localStorage.getItem(`${window.location.hostname}.login`);

    // if (!getToken) {
    //   history.push("/login");
    //   window.scroll(0, 0);
    //   return true;
    // }

    let tempId: any = "";

    let userId: any = "";

    if (localStorage.getItem("tempUser")) {
      tempId = localStorage.getItem("tempUser");
    }

    if (getToken) {
      const { data } = JSON.parse(getToken);
      const { id }: any = jwtDecode(data);

      userId = id;
    }

    let error = false;
    let cartDetails = {
      productId: sProduct.id.toString(),
      userId: userId ? userId : tempId,
      qty: 1,
      // color: selectedProductColor,
      // size: selectedProductPrice,
      fileType: "",
      image: ""
    };

    if (!_.map(wish, "id").includes(sProduct.id)) {
      addToWishlist(cartDetails)
        .then((data) => {
          toast.custom(
            (t) => (
              <Transition
                appear
                show={t.visible}
                className="p-4 max-w-md w-full bg-white dark:bg-slate-800 shadow-lg rounded-2xl pointer-events-auto ring-1 ring-black/5 dark:ring-white/10 text-slate-900 dark:text-slate-200"
                enter="transition-all duration-150"
                enterFrom="opacity-0 translate-x-20"
                enterTo="opacity-100 translate-x-0"
                leave="transition-all duration-150"
                leaveFrom="opacity-100 translate-x-0"
                leaveTo="opacity-0 translate-x-20"
              >
                <p className="block text-sm font-semibold leading-none">
                  Added to WishList !
                </p>
                <div className="border-t border-slate-200 dark:border-slate-700 my-4" />
                {renderProductCartOnNotify({ size: "" })}
              </Transition>
            ),
            { position: "top-right", id: "nc-product-notify", duration: 3000 }
          );
          // setlopen(false);
          // addToast(`${product?.productName} Added to Cart`, { appearance: "success", autoDismiss: true });
          // toast.custom(
          //   (t) => (
          //     <NotifyAddTocart
          //       productImage={`${IMAGE_SERVER_URL_DEV}${JSON.parse(images).filter((item: { thumbnail: boolean; }) => item?.thumbnail === true)[0]?.path}`}
          //       qualitySelected={1}
          //       show={t.visible}
          //       sizeSelected={""}
          //       variantActive={variantActive}
          //       selectedProductColor={""}
          //       selectedProduct={{productName:productName,unitPrice:unitprice}}
          //     />
          //   ),
          //   { position: "top-right", id: "nc-product-notify", duration: 3000 }
          // );

          UserWishList().then((res) => {
            setWish(res);
          });
          // this.setState({
          //   color2: "#645bff",
          //   alertClass: "success",
          //   msg: `${this.state.productData?.displayName} Added to Cart`,
          //   open_toast: true,
          // });
        })
        .catch((err) => {
          // setlopen(false);
          addToast(`${err.response.data.message}`, "warning");
          // this.setState({
          //   color2: "#645bff",
          //   alertClass: "success",
          //   msg: `${this.state.productData?.displayName} product add failed`,
          //   open_toast: true,
          // });
        });
    } else {
      DeleteWishlist(
        wish.filter((item: any) => item?.id === sProduct?.id)[0]?.wishlistId
      )
        .then((res) => {
          toast.custom(
            (t) => (
              <Transition
                appear
                show={t.visible}
                className="p-4 max-w-md w-full bg-white dark:bg-slate-800 shadow-lg rounded-2xl pointer-events-auto ring-1 ring-black/5 dark:ring-white/10 text-slate-900 dark:text-slate-200"
                enter="transition-all duration-150"
                enterFrom="opacity-0 translate-x-20"
                enterTo="opacity-100 translate-x-0"
                leave="transition-all duration-150"
                leaveFrom="opacity-100 translate-x-0"
                leaveTo="opacity-0 translate-x-20"
              >
                <p className="block text-sm font-semibold leading-none">
                  Removed from WishList !
                </p>
                <div className="border-t border-slate-200 dark:border-slate-700 my-4" />
                {renderProductCartOnNotify({ size: "" })}
              </Transition>
            ),
            { position: "top-right", id: "nc-product-notify", duration: 3000 }
          );
          UserWishList().then((res) => {
            setWish(res);
          });
          //  this.setState(
          //    {
          //      open_toast: true,
          //      alertClass: "error",
          //      msg: `${data.displayName} Removed`,
          //      color: "#635bff",
          //    },
          //    () => this.getAllCart()
          //  );
        })
        .catch((error) => console.error("delete cart item error", error));
    }
  };

  const renderProductCartOnNotify = ({ size }: { size?: string }) => {
    return (
      <div className="flex ">
        <div className="h-24 w-20 flex-shrink-0 overflow-hidden rounded-xl bg-slate-100">
          <img
            src={`${IMAGE_SERVER_URL_DEV}${sProduct?.thumbnail?.path}`}
            alt={selectedProduct.productName}
            className="h-full w-full object-cover object-center"
          />
        </div>

        <div className="ml-4 flex flex-1 flex-col">
          <div>
            <div className="flex justify-between ">
              <div>
                <h3 className="text-sm font-medium ">
                  {selectedProduct.productName}
                </h3>
                {/* <p className="mt-1 text-xs text-slate-500 dark:text-slate-400">
                  <span>
                    {variants ? variants[variantActive].name : `Natural`}
                  </span>
                  <span className="mx-2 border-l border-slate-200 dark:border-slate-700 h-4"></span>
                  <span>{size || "XL"}</span>
                </p> */}
              </div>
              <Prices price={selectedProduct.unitprice} className="mt-0.5" />
            </div>
          </div>
          <div className="flex flex-1 items-end justify-between text-xs">
            {/* <p className="text-gray-500 dark:text-slate-400">Qty 1</p> */}

            <div className="flex">
              <a href="/account-savelists">
                <button
                  type="button"
                  className="font-medium text-[#74BC1F] dark:text-primary-500 "
                >
                  View WishList
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const notifyAddTocart = () => {
    const getToken: any = localStorage.getItem(
      `${window.location.hostname}.login`
    );

    // if (!getToken) {
    //   history.push("/login");
    //   window.scroll(0, 0);
    //   return true;
    // }

    let tempId: any = "";

    let userId: any = "";

    let userstatus: any = 0;

    if (localStorage.getItem("tempUser")) {
      tempId = localStorage.getItem("tempUser");
    }

    if (getToken) {
      const { data } = JSON.parse(getToken);
      const { id }: any = jwtDecode(data);
      const { status }: any = jwtDecode(data);
  
      userId = id;
      userstatus = status;
    }

    let error = false;
    let cartDetails = {
      productId: selectedProductId.toString(),
      userId: userId ? userId : tempId,
      qty: qualitySelected,
      color: selectedProductColor,
      size: selectedProductPrice,
      fileType: "",
      image: ""
    };

    if (userstatus === 1) {
    addToCard(cartDetails)
      .then((data) => {
        setlopen(false);
        // addToast(`${product?.productName} Added to Cart`, { appearance: "success", autoDismiss: true });
        toast.custom(
          (t) => (
            <NotifyAddTocart
              productImage={`${IMAGE_SERVER_URL_DEV}${JSON.parse(sProduct?.imgArray)[0].path}`}
              qualitySelected={qualitySelected}
              show={t.visible}
              sizeSelected={""}
              variantActive={0}
              selectedProductColor={""}
              selectedProduct={sProduct}
            />
          ),
          { position: "top-right", id: "nc-product-notify", duration: 3000 }
        );
        CartCount().then((res) => {
          setCart(res);
        });
        // this.setState({
        //   color2: "#645bff",
        //   alertClass: "success",
        //   msg: `${this.state.productData?.displayName} Added to Cart`,
        //   open_toast: true,
        // });
      })
      .catch((err) => {
        setlopen(false);
        addToast(`${err.response.data.message}`, "warning");
        // this.setState({
        //   color2: "#645bff",
        //   alertClass: "success",
        //   msg: `${this.state.productData?.displayName} product add failed`,
        //   open_toast: true,
        // });
      });
    } else {
      addToast(`Your acccount is not activated`, "warning");
    }
  };
  console.log(sProduct);
  const renderVariants = () => {
    if (!variants || !variants.length) {
      return null;
    }

    return (
      <div>
        <label htmlFor="">
          {selectedProductColor !== "none" && (
            <span className="text-xs font-medium">
              Color:
              <span className="ml-1 font-semibold capitalize">
                {/* {variants[variantActive].name} */}
                {selectedProductColor}
              </span>
            </span>
          )}
        </label>
        {/* <div className="flex mt-3">
          {variants.map((variant, index) => (
            <div
              key={index}
              onClick={() => setVariantActive(index)}
              className={`relative flex-1 max-w-[75px] h-10 sm:h-11 rounded-full border-2 cursor-pointer ${variantActive === index
                ? "border-[#74BC1F] dark:border-primary-500"
                : "border-transparent"
                }`}
            >
              <div className="absolute inset-0.5 rounded-full overflow-hidden z-0">
                <img
                  src={variant.thumbnail}
                  alt=""
                  className="absolute w-full h-full object-cover"
                />
              </div>
            </div>
          ))}
        </div> */}
        <div className="flex space-x-2 mt-3">
          {/* {variants.map((variant, index) => (
            <div
              key={index}
              onClick={() => setVariantActive(index)}
              className={`relative w-9 h-9 rounded-full overflow-hidden z-10 border cursor-pointer ${variantActive === index
                ? getBorderClass(variant.color)
                : "border-transparent"
                }`}
              title={variant.name}
            >
              <div
                className={`absolute inset-0.5 rounded-full z-0 ${variant.color}`}
              ></div>
            </div>
          ))} */}
          {sProduct?.variation?.map((variant: any, index: any) => (
            <div
              key={index}
              onClick={() => {
                setVariantActive(index);
                setSelectedProductColor(variant.color);
              }}
              className={`relative w-9 h-9 rounded-full overflow-hidden z-10 border cursor-pointer ${
                selectedProductColor === variant.color
                  ? getBorderClass(lowerCase(variant.color))
                  : "border-transparent"
              }`}
              title={variant.color}
            >
              <div
                className={`absolute inset-0.5 rounded-full z-0 ${getBackgrounClass(
                  lowerCase(variant.color)
                )}`}
              ></div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const renderSizeList = () => {
    if (!allOfSizes || !sizes || !sizes.length) {
      return null;
    }
    return (
      <div>
        <div className="flex justify-between font-medium text-xs">
          {sizeSelected !== "none" && (
            <>
              <label htmlFor="">
                <span className="">
                  Size / Model Number:
                  <span className="ml-1 font-semibold">{sizeSelected}</span>
                </span>
              </label>
              {/* <a
                target="_blank"
                rel="noopener noreferrer"
                href="##"
                className="text-[#74BC1F] hover:text-primary-500"
              >
                See sizing chart
              </a> */}
            </>
          )}
        </div>
        <div className="grid grid-cols-5 sm:grid-cols-4 gap-2 mt-3">
          {sProduct?.sizes?.map((size: any, index: any) => {
            const isActive = size.size === sizeSelected;
            // const sizeOutStock = !sizes.includes(size);
            const sizeOutStock = false;
            return (
              <div
                key={index}
                className={`relative rounded-lg border flex items-center justify-center 
                text-xs sm:text-xs uppercase font-bold select-none overflow-hidden z-0 py-3 ${
                  sizeOutStock
                    ? "text-opacity-20 dark:text-opacity-20 cursor-not-allowed"
                    : "cursor-pointer"
                } ${
                  isActive
                    ? "bg-[#74BC1F] border-[#74BC1F] text-white hover:bg-[#74BC1F]"
                    : "border-slate-300 dark:border-slate-600 text-slate-900 dark:text-slate-200 hover:bg-neutral-50 dark:hover:bg-neutral-700"
                }`}
                onClick={() => {
                  if (sizeOutStock) {
                    return;
                  }
                  setSizeSelected(size.size);
                }}
              >
                {size.size}
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const renderStatus = () => {
    if (!status) {
      return null;
    }
    const CLASSES =
      "absolute top-3 left-3 px-2.5 py-1.5 text-[10px] bg-red-600 dark:bg-slate-900 nc-shadow-lg rounded-full flex items-center justify-center text-slate-200 text-slate-900 dark:text-slate-300";
    // if (status === "Hot") {
    //   return (
    //     <div className={CLASSES}>
    //       <SparklesIcon className="w-3.5 h-3.5" />
    //       <span className="ml-1 leading-none">{status}</span>
    //     </div>
    //   );
    // }
    // if (status === "50% Discount") {
    //   return (
    //     <div className={CLASSES}>
    //       <IconDiscount className="w-3.5 h-3.5" />
    //       <span className="ml-1 leading-none">{status}</span>
    //     </div>
    //   );
    // }
    // if (status === "Sold Out") {
    //   return (
    //     <div className={CLASSES}>
    //       <NoSymbolIcon className="w-3.5 h-3.5" />
    //       <span className="ml-1 leading-none">{status}</span>
    //     </div>
    //   );
    // }
    // if (status === "limited edition") {
    //   return (
    //     <div className={CLASSES}>
    //       <ClockIcon className="w-3.5 h-3.5" />
    //       <span className="ml-1 leading-none">{status}</span>
    //     </div>
    //   );
    // }
    if (sProduct?.latest === 1) {
      return (
        <div className={CLASSES}>
          <SparklesIcon className="w-3.5 h-3.5" />
          <span className="ml-1 leading-none">{"Hot"}</span>
        </div>
      );
    }
    if (sProduct?.featured === 1) {
      return (
        <div className={CLASSES}>
          <SparklesIcon className="w-3.5 h-3.5" />
          <span className="ml-1 leading-none">{"Popular"}</span>
        </div>
      );
    }
    if (sProduct?.callForPrice === 1) {
      return (
        <div className={CLASSES}>
          <SparklesIcon className="w-3.5 h-3.5" />
          <span className="ml-1 leading-none">{"Call For Price"}</span>
        </div>
      );
    }
    return null;
  };

  const [pSku, setPSku] = useState(null);

  useEffect(() => {
    const getSkuByVar = () => {
      setPSku(selectedProduct.sku);
    };
    getSkuByVar();
  }, [selectedProduct]);
  console.log("sele", selectedProduct);

  function stripHtml(html: string): string {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  }

  const [currUser, setcurrUser] = useState<any>("");

  const cleanDescription = stripHtml(sProduct?.description);

  const getCurrentUser = () => {
    CurrentUser()
      .then((res) => {
        if (res.statusCode === 200) {
          const cData = res.data[0];
          const obj = {
            id: cData.id,
            email: cData?.email,
            firstName: cData?.firstName,
            lastName: cData?.lastName,
            phone: cData?.mobileNumber,
            companyAddress: cData?.companyAddress
          };
          //   this.setState({
          //     firstName: cData?.firstName,
          //     lastName: cData?.lastName,
          //     email: cData?.email,
          //     tele: cData?.phone,
          //   });

          setcurrUser(cData);
          // setName(cData?.firstName + " " + cData?.lastName);

          localStorage.setItem(
            `${window.location.hostname}.profile`,
            JSON.stringify(obj)
          );
        }
      })
      .catch((error) => {
        console.log("error", error);
        console.log("current user error", error.response.data.message);
      });
  };

  React.useEffect(() => {
    getCurrentUser();
  }, []);

  const [deliveryDate, setdeliveryDate] = useState("");

  const getDeliveryDate = () => {
    getDeliveryDateByCart(currUser?.company_id)
      .then((res) => {
        setdeliveryDate(res.data?.nextDeliveryDate);
      })
      .catch((error) => {
        console.log("Error", error.response);
        setdeliveryDate("");
        // setOpenToast(true);
        // setColor(COLORS.Orange);
        // setAlertClass("error");
        // setMsg(error.response.data.message);
      });
  };

  React.useEffect(() => {
    getDeliveryDate();
  }, [currUser]);

  let discount = (
    ((parseFloat(sProduct.mrp) - parseFloat(sProduct.specialprice)) /
      parseFloat(sProduct.mrp)) *
    100
  ).toString();

  const renderSectionContent = () => {
    return (
      <div className="space-y-7 2xl:space-y-8 font-berthold">
        {/* ---------- 1 HEADING ----------  */}
        <div className=" ">
          <div className=" relative">
            <h2 className="font-berthold tracking-wide text-xl sm:text-2xl md:text-5xl text-[#010a4f] font-medium">
              {/* Heavy Weight Shoes */}
              {sProduct?.productName}
            </h2>
         
              {sProduct?.specialprice && (
                <div className=" absolute top-[-80%] right-0">
                  <div className="relative flex justify-center items-center w-[80px] h-[80px]">
                    <img src={save} className="w-full" alt="" />
                    <div className="absolute inset-0 flex items-center font-berthold pl-2 flex-col justify-center text-center text-white font-bold text-xs leading-tight transform rotate-[25deg] -translate-y-2">
                      <div>SAVE</div>
                      <div className="text-[18px]">{parseInt(discount)}%</div>
                    </div>
                  </div>
                </div>
              )}
         
          </div>

          <div className="flex flex-col mt-4 mb-3 ">
            <p className="text-[14px] text-[#010a4f] font-GlacialIndifference leading-[20px] md:w-[95%] ">
              {cleanDescription}
            </p>
            {/* <p className="text-[13px] leading-[15px] md:w-[80%] pb-5 border-[#a6a6a6] border-b-[1px]"></p> */}
          </div>

          {
            currUser && (
              <div className="flex flex-col mt-4 mb-3 ">
              <p className="text-[18px] text-black leading-[20px] md:w-[95%] ">
                🚚 Please note...​Your products will be delivered to ( {currUser?.companyAddress} ) on ( {deliveryDate} ). There are limits to how many
                products you can purchase a month
              </p>
              {/* <p className="text-[13px] leading-[15px] md:w-[80%] pb-5 border-[#a6a6a6] border-b-[1px]"></p> */}
            </div>
            )
          }
       


          {sProduct?.buyone === 1 && (
        <div className=" font-berthold text-red-600 text-lg mt-3">Buy One Get 1 FREE</div>
      )}

          {/* <span className="flex mt-2">
            Barcode :
            <span className="ml-1 font-semibold">
              {sProduct?.barCode !== "undefined" ? sProduct?.barCode : ""}
            </span>
          </span> */}

          <div className="flex items-center space-x-4 sm:space-x-5">
            {/* <div className="flex text-lg font-semibold">$112.00</div> */}
            <div className="w-full flex flex-col md:flex-row gap-1 md:gap-10 text-sm text-[#545454]  md:items-center">
              <div className=" w-full flex justify-start font-berthold text-[#010a4f] text-3xl items-end gap-5 pt-4">
                <div
                  className={`${
                    sProduct?.specialprice &&
                    " text-[30px] text-[#8a8888] line-through "
                  }`}
                >
                  Rs.{sProduct?.mrp}
                </div>
                {sProduct?.specialprice && (
                  <div className="">Rs.{sProduct?.specialprice}</div>
                )}
              </div>
            </div>

            {(sProduct?.latest === 1 ||
              sProduct?.featured === 1 ||
              sProduct?.callForPrice === 1) && (
              <>
                {selectedProduct?.callForPrice != 1 && (
                  <div className="h-7 border-l border-slate-300 dark:border-slate-700"></div>
                )}

                <div className="flex items-center">
                  {/* <a
    href="#reviews"
    className="flex items-center text-xs font-medium"
  >
    <StarIcon className="w-5 h-5 pb-[1px] text-yellow-400" />
    <div className="ml-1.5 flex">
      <span>4.9</span>
      <span className="block mx-2">·</span>
      <span className="text-slate-600 dark:text-slate-400 underline">
        142 reviews
      </span>
    </div>
  </a>
  <span className="hidden sm:block mx-2.5">·</span> */}
                  {/* <div className="hidden sm:flex items-center text-xs">
                    <SparklesIcon className="w-3.5 h-3.5" />
                
                    <span className="ml-1 leading-none">
                      {sProduct?.latest === 1
                        ? "Hot"
                        : sProduct?.featured === 1
                        ? "Popular"
                        : sProduct?.callForPrice === 1
                        ? "Call For Price"
                        : ""}
                    </span>
                  </div> */}
                </div>
              </>
            )}
          </div>
        </div>

        {/* ---------- 3 VARIANTS AND SIZE LIST ----------  */}

        {/* <div className="">{renderVariants()}</div>
        <div className="">{renderSizeList()}</div> */}

        {/*  ---------- 4  QTY AND ADD TO CART BUTTON */}
        <div className="flex space-x-3.5 pb-10">
          {sProduct?.availableStocks !== 0 ? (
            <div className="flex items-center justify-center text-[#010a4f] px-4 py-2  rounded-lg border-2 border-[#8a8888]">
              <NcInputNumber
                max={sProduct?.availableStocks}
                defaultValue={qualitySelected}
                onChange={setQualitySelected}
              />
            </div>
          ) : (
            <></>
          )}

          <button
            disabled={!currUser}
            onClick={notifyAddTocart}
            className={`font-berthold tracking-wider w-fit px-5 py-2 text-xl font-normal rounded-lg uppercase ${
              currUser ? "bg-[#02871ab2]" : "bg-[#02871a]"
            }  text-white`}
          >
            Add to cart
          </button>
        </div>

        <Link
          to={`/shop`}
          className={`font-berthold tracking-wider   w-fit px-5 py-[12px] text-xl font-normal rounded-lg uppercase
           bg-[#010a4f] text-white`}
        >
          Shop All products
        </Link>

        {/* ---------- 5 ----------  */}
        {/* <AccordionInfo /> */}

        {/* ---------- 6 ----------  */}
        {/* <div className="">
          <div className="px-5 py-3 rounded-lg text-white text-sm bg-gradient-to-r from-[#010a4f] to-[#e63a42]">
            {deliveryDate
              ? `Your order is expected to be delivered on ${deliveryDate}. However, you will receive an SMS notification confirming the delivery date.`
              : `Please login  to check dlivery date`}
          </div>
          <Policy />
        </div> */}
      </div>
    );
  };

  const renderDetailSection = () => {
    return (
      <div className="">
        <h2 className="text-xl font-semibold">Product Details</h2>
        <div className="prose prose-sm sm:prose dark:prose-invert sm:max-w-4xl mt-7">
          {parse(selectedProduct.description)}
          {/* <p>
            The patented eighteen-inch hardwood Arrowhead deck --- finely
            mortised in, makes this the strongest and most rigid canoe ever
            built. You cannot buy a canoe that will afford greater satisfaction.
          </p>
          <p>
            The St. Louis Meramec Canoe Company was founded by Alfred Wickett in
            1922. Wickett had previously worked for the Old Town Canoe Co from
            1900 to 1914. Manufacturing of the classic wooden canoes in Valley
            Park, Missouri ceased in 1978.
          </p>
          <ul>
            <li>Regular fit, mid-weight t-shirt</li>
            <li>Natural color, 100% premium combed organic cotton</li>
            <li>
              Quality cotton grown without the use of herbicides or pesticides -
              GOTS certified
            </li>
            <li>Soft touch water based printed in the USA</li>
          </ul> */}
        </div>
      </div>
    );
  };

  const renderReviews = () => {
    return (
      <div className="">
        {/* HEADING */}
        <h2 className="text-xl font-semibold flex items-center">
          <StarIcon className="w-7 h-7 mb-0.5" />
          <span className="ml-1.5"> 4,87 · 142 Reviews</span>
        </h2>

        {/* comment */}
        <div className="mt-10">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-y-11 gap-x-28">
            <ReviewItem />
            <ReviewItem
              data={{
                comment: `I love the charcoal heavyweight hoodie. Still looks new after plenty of washes. 
                  If you’re unsure which hoodie to pick.`,
                date: "December 22, 2021",
                name: "Stiven Hokinhs",
                starPoint: 5
              }}
            />
            <ReviewItem
              data={{
                comment: `The quality and sizing mentioned were accurate and really happy with the purchase. Such a cozy and comfortable hoodie. 
                Now that it’s colder, my husband wears his all the time. I wear hoodies all the time. `,
                date: "August 15, 2022",
                name: "Gropishta keo",
                starPoint: 5
              }}
            />
            <ReviewItem
              data={{
                comment: `Before buying this, I didn't really know how I would tell a "high quality" sweatshirt, but after opening, I was very impressed. 
                The material is super soft and comfortable and the sweatshirt also has a good weight to it.`,
                date: "December 12, 2022",
                name: "Dahon Stiven",
                starPoint: 5
              }}
            />
          </div>

          <ButtonSecondary
            onClick={() => setIsOpenModalViewAllReviews(true)}
            className="mt-10 border border-slate-300 dark:border-slate-700 "
          >
            Show me all 142 reviews
          </ButtonSecondary>
        </div>
      </div>
    );
  };

  return (
    <>
      <Helmet>
        <title>Shop | Silvermill</title>
        <meta
          name="description"
          content="Welcome to Oz Cleaning Supplies, your premier destination for top-quality cleaning
    supplies in Dandenong!"
        />

        <meta name="keywords" content="Cleaning supplies Dandenong" />
        <link rel="canonical" href={path + "/"} />
        <script type="application/ld+json">{`
  {
  "@context" : "http://schema.org",
  "@type" : "LocalBusiness",
  "name": "Oz Cleaning Supplies",
  "image" : [ "https://backendozecomadmin.ozcleaningsupplies.com.au/uploads/logo/1670255682167.jpeg", "https://www.ozcleaningsupplies.com.au/" ],
  "telephone": "+61 0397940654",
  "email": "info@ozcleaningsupplies.com.au",
  "address": {
    "@type": "PostalAddress",
    "streetAddress" : "167 Cheltenham Road",
    "addressLocality": "Dandenong",
    "addressRegion" : "VIC ",
    "addressCountry": "Australia",
    "postalCode" : "3175"
  },
  "url": "https://www.ozcleaningsupplies.com.au${path}"
}
    `}</script>
      </Helmet>
      <LoadingSpinner open={lopen} />
      <div className={`nc-ProductDetailPage ${className}`}>
        {/* MAIn */}
        <main className="  mt-5 lg:mt-11">
          <div className="lg:flex container gap-[5%]">
            {/* CONTENT */}
            <div className="w-full lg:w-[35%] ">
              {/* HEADING */}
              {/* <div className="relative">
                <div className="aspect-w-16 aspect-h-8">
                  <img
                    src={prodImages[0]?.original}
                    className="w-full rounded-2xl object-contain"
                    alt="product detail 1"
                  />
                 
                     <div
                    className="absolute inset-0 bg-[#9ec8a0] bg-opacity-10 hover:bg-slate-900 hover:bg-opacity-20 hover:opacity-60 transition-opacity cursor-pointer rounded-2xl"
                    onClick={() => handleOpenModal(0)}
                  />
                </div>
                <div
                  className="absolute hidden md:flex md:items-center md:justify-center left-3 bottom-3 px-4 py-2 rounded-xl bg-white text-slate-500 cursor-pointer hover:bg-slate-200 z-10"
                  onClick={() => handleOpenModal(0)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={1.5}
                      d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"
                    />
                  </svg>
                  <span className="ml-2 text-neutral-800 text-xs font-medium">
                    Show all photos
                  </span>
                </div>
                {renderStatus()}

                <LikeButton
                  liked={_.map(wish, "id").includes(sProduct?.id)}
                  className="absolute right-3 top-3 "
                  addWish={notifyAddToWish}
                />
              </div> */}
              <div className="relative w-full flex flex-col z-1 group">
                <Swiper
                  spaceBetween={10}
                  thumbs={{ swiper: thumbsSwiper }}
                  modules={[FreeMode, Thumbs]}
                  className="  w-full px-0 "
                >
                  {sProduct?.imgArray &&
                    JSON.parse(sProduct?.imgArray).map(
                      (item: { path: string }, index: number) => {
                        return (
                          <SwiperSlide className="">
                            <img
                              src={`${IMAGE_SERVER_URL_DEV}${item.path}`}
                              className="object-cover w-full h-full drop-shadow-xl"
                            />
                          </SwiperSlide>
                        );
                      }
                    )}
                </Swiper>

                <Swiper
                  onSwiper={(swiper: SwiperRef) => {
                    setThumbsSwiper(swiper);
                    console.log("ss");
                  }}
                  spaceBetween={10}
                  slidesPerView={3}
                  freeMode={true}
                  watchSlidesProgress={true}
                  modules={[FreeMode, Navigation, Thumbs]}
                  className="w-full mt-5 flex justify-between "
                >
                  {sProduct?.imgArray &&
                    JSON.parse(sProduct?.imgArray).map(
                      (item: { path: string }, index: number) => {
                        return (
                          <SwiperSlide className=" cursor-pointer w-[32%]">
                            <img
                              src={`${IMAGE_SERVER_URL_DEV}${item.path}`}
                              className="object-cover w-full h-full drop-shadow-xl"
                            />
                          </SwiperSlide>
                        );
                      }
                    )}
                </Swiper>

                {/* <Link to={`/product-detail/${productId}`}>
              <div className="flex w-full  overflow-hidden rounded-lg">
                <img
                  src={
                    imgArray && JSON.parse(imgArray).length > 0
                      ? `${IMAGE_SERVER_URL_DEV}${
                          JSON.parse(imgArray).filter(
                            (item: { thumbnail: boolean }) =>
                              item?.thumbnail === true
                          )[0]?.path || JSON.parse(imgArray)[0]?.path
                        }`
                      : EmptyIMG
                  }
                  className="object-cover w-full h-full drop-shadow-xl"
                />
              </div>
            </Link> */}
                <div className="flex items-center w-full">
                  {/* {imgArray &&
                JSON.parse(imgArray).map(
                  (item: { path: string }, index: number) => {
                    return (
                      <div className="flex w-[30%] items-center" key={index}>
                        <img
                          src={`${IMAGE_SERVER_URL_DEV}${item.path}`}
                          className="object-cover w-full h-full drop-shadow-xl"
                        />
                      </div>
                    );
                  }
                )} */}
                </div>

                {/* <span className="absolute inset-0 bg-[#9ec8a0] bg-opacity-10 transition-opacity"></span> */}

                {/* <ProductStatus
              status={
                latest === 1
                  ? "latest"
                  : featured === 1
                  ? "featured"
                  : data2?.callForPrice === 1
                  ? "callPrice"
                  : ""
              }
            /> */}

                {/* <LikeButton
              liked={_.map(wish, "id").includes(id)}
              className="absolute top-3 right-3 z-10"
              addWish={notifyAddToWish}
            /> */}

                {/* {sizes ? renderSizeList() : renderGroupButtons()} */}
                {/* {renderGroupButtons()} */}
              </div>

              {/* <div className="grid grid-cols-2 gap-3 mt-3 sm:gap-6 sm:mt-6 xl:gap-8 xl:mt-8">
                {prodImages.length > 1 &&
                  [...prodImages].slice(1, 3).map((item: any, index: any) => {
                    return (
                      <div
                        key={index}
                        className="aspect-w-11 xl:aspect-w-10 2xl:aspect-w-11 aspect-h-16"
                      >
                        <img
                          src={item?.original}
                          className="w-full rounded-2xl object-cover"
                          alt="product detail 1"
                        />
               
                        <div
                          className="absolute inset-0 bg-slate-900 bg-opacity-20 opacity-0 hover:opacity-60 transition-opacity cursor-pointer"
                          onClick={() => handleOpenModal(index + 1)}
                        />
                      </div>
                    );
                  })}
              </div> */}
            </div>

            {/* MODAL PHOTOS */}
            {/* <ModalPhotos
              imgs={_.map(prodImages, "original")}
              isOpen={isOpen}
              onClose={handleCloseModal}
              initFocus={openFocusIndex}
              uniqueClassName="nc-ProductDetailPage2__modalPhotos"
            /> */}

            {/* SIDEBAR */}
            <div className="w-full lg:w-[55%] pt-10 lg:pt-0 lg:pl-7 xl:pl-9 2xl:pl-10">
              {renderSectionContent()}
            </div>
          </div>

          {/* DETAIL AND REVIEW */}
          <div className="mt-12 sm:mt-16 ">
            {/* <div className="block xl:hidden">
              <Policy />
            </div> */}

            {selectedProduct.description && renderDetailSection()}

            {/* <hr className="border-slate-200 dark:border-slate-700" /> */}

            {/* {renderReviews()}

          <hr className="border-slate-200 dark:border-slate-700" /> */}

            {/* OTHER SECTION */}
            {/* <SectionSliderProductCard
              heading="Customers also purchased"
              subHeading=""
              headingFontClassName="text-xl font-semibold"
              currUser={currUser}
              headingClassName="mb-10 text-neutral-900 dark:text-neutral-50"
              mainCategory={sProduct.mainId}
            /> */}

            <div className="w-full flex flex-col">
              <img src={pageBottom} alt="" />
            </div>
            <div className="bg-[#010a4f] pt-[5%] flex flex-col items-center">
              <div className=" text-2xl md:text-5xl font-berthold text-[#08afb2] ">
                Don't just take our word for it
              </div>
              <div className="text-4xl md:text-6xl font-berthold text-center md:text-start text-white uppercase">
                Here's what OUR CUSTOMERS SAY...
              </div>
              <div className=" flex flex-col w-full items-center px-[10%] pt-[5%] ">
                <Swiper
                  spaceBetween={30}
                  slidesPerView={3}
                  loop={true}
                  pagination={{
                    clickable: true,
                    el: ".custom-pagination"
                  }}
                  modules={[Pagination]}
                  breakpoints={{
                    640: {
                      slidesPerView: 1 // 1 slide on mobile
                    },
                    768: {
                      slidesPerView: 2 // 2 slides on tablets
                    },
                    1024: {
                      slidesPerView: 3 // 3 slides on desktop
                    }
                  }}
                  className="flex w-full text-white"
                >
                  {reviews?.length > 0 &&
                    reviews?.map((item: any, index) => {
                      return (
                        <SwiperSlide className="w-[30%] flex ">
                          <img
                            src={`${IMAGE_SERVER_URL_DEV}${item.image}`}
                            alt=""
                          />
                        </SwiperSlide>
                      );
                    })}

                  {/* <SwiperSlide className="w-[30%] flex ">
              <img src={reviewimg} alt="" />
            </SwiperSlide>
            <SwiperSlide className="w-[30%] flex ">
              <img src={reviewimg} alt="" />
            </SwiperSlide>
            <SwiperSlide className="w-[30%] flex ">
              <img src={reviewimg} alt="" />
            </SwiperSlide> */}
                </Swiper>

                <div className="custom-pagination mt-8 flex justify-center space-x-3">
                  {/* Pagination dots are automatically generated by Swiper */}
                </div>

                <div className="mt-16 font-justhand tracking-wide w-fit px-5 py-2 text-2xl md:text-4xl  rounded-lg  bg-[#010a4f] text-white">
                  All comments are those made by Silvermill customers on our
                  social media platforms
                </div>
              </div>
            </div>

            {/* SECTION */}
            {/* <div className="pb-20 xl:pb-28 lg:pt-14">
              <SectionPromo2 />
            </div> */}
          </div>
        </main>
        {/* <div className="pt-12">
          <SectionPromo2 />
        </div> */}
        {/* MODAL VIEW ALL REVIEW */}
        {/* <ModalViewAllReviews
          show={isOpenModalViewAllReviews}
          onCloseModalViewAllReviews={() => setIsOpenModalViewAllReviews(false)}
        /> */}
      </div>
    </>
  );
};

export default ProductDetailPage;
