import React, { FC, useState, useEffect, useContext } from "react";
import { Helmet } from "react-helmet";
import SectionSliderCollections from "components/SectionSliderLargeProduct";
import SectionPromo1 from "components/SectionPromo1";
// import ProductCard from "components/ProductCard";
import ProductCardNew from "components/ProductCardNew";
import { PRODUCTS } from "data/data";
import SidebarFilters from "./SidebarFilters";
import pagetop from "images/page-top.png";
import pageBottom from "images/pagebottom.png";
import save from "images/save.png";
import productTitle from "images/productsTitle.png";

import heroimg from "images/shophero.png";
import Pagination from "shared/Pagination/PaginationNew";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Swiper as SwiperCore } from "swiper";
import { Pagination as SwsPagintion, Navigation } from "swiper/modules";
import notime6 from "images/shoppageads/6.jpg";
import notime7 from "images/shoppageads/7.jpg";
import notime8 from "images/shoppageads/8.jpg";
import notime9 from "images/shoppageads/9.jpg";
import notime10 from "images/shoppageads/10.jpg";
import {
  Categories,
  CurrentUser,
  getAllProducts,
  getCartList,
  getDeliveryDateByCart,
  getTestimonial
} from "Services/API/Get";
import _, { lowerCase } from "lodash";
import LoadingSpinner from "shared/LoadingSpinner";
import { Link, useLocation } from "react-router-dom";
import newbackground from "images/newbackground.png";
import { Autocomplete } from "@mui/material";
import Input from "shared/Input/Input";
import EmptyIMG from "images/empty.png";
import { useHistory } from "react-router-dom";
import { IMAGE_SERVER_URL_DEV } from "Constant/System";
import Radio from "shared/Radio/Radio";
import { addToCard } from "Services/API/Post";
import toast from "react-hot-toast";
import NotifyAddTocart from "components/NotifyAddTocart";
import CartCount from "Constant/CartCount";
import { addToast } from "shared/Toast";
import jwtDecode from "jwt-decode";
import CartContext from "Context/CartContext";

export interface PageCollection2Props {
  className?: string;
}

const PageCollection2: FC<PageCollection2Props> = ({ className = "" }) => {
  const location = useLocation();
  const path = location.pathname;
  const history = useHistory();
  const [nProducts, setnProducts] = useState([]);
  const [allSizes, setallSizes] = useState<any | null>([]);
  const [allColours, setallColours] = useState<any | null>([]);
  const [allSubs, setallSubs] = useState<any | null>([]);
  const [categories, setcategories] = useState([]);
  const [tags, settags] = useState<any | null>([]);
  const [selectedSize, setselectedSize] = useState("");
  const [selectedColour, setselectedColour] = useState("");
  const [selectedCat, setselectedCat] = useState<any | null>("");
  const [minPrice, setminPrice] = useState<any | null>(0);
  const [maxPrice, setmaxPrice] = useState<any | null>(2000);
  const [selectedSub, setselectedSub] = useState<any>("");
  const [deliveryDate, setdeliveryDate] = useState("");

  const [selectedSubCat, setselectedSubCat] = useState([]);
  const [dataCount, setcount] = useState(0);
  const [search, setsearch] = useState("");
  const [lopen, setlopen] = useState(true);
  const [currUser, setcurrUser] = useState<any>("");
  const [mainDesc, setmainDesc] = useState<any>("");
  const [subDesc, setsubDesc] = useState("");
  const [filterSortValue, setFilterSortValue] = useState("");
  const [filterSortIndex, setfilterSortIndex] = useState(0);
  const [reviews, setreviews] = useState([]);

  const [pageLimit, setpageLimit] = useState(9);
  const [currentPage, setCurrentPage] = useState(1);

  const [Scat, setScat] = useState(false);

  const [allProd, setallProd] = useState<any | null>([]);

  const [search2, setsearch2] = useState("");

  const getallCategory = () => {
    Categories()
      .then((response) => {
        setcategories(response.data);
        // this.setState(
        //   {
        //     array: res.data.result,
        //     filter: res.data.result,
        //     showPages: m,
        //     showOff: true,
        //   },
        //   async () => {
        //     await this.getPaginationGroup(m);
        //   }
        // );
      })
      .catch(() => {
        // this.setState({
        //   array: [],
        //   filter: [],
        //   showPages: 1,
        //   showOff: false,
        // });
      });
  };

  useEffect(() => {
    getallCategory();
    // window.scrollTo({
    //   top: 0,
    //   behavior: "smooth"
    // });
  }, []);

  const getTestimonials = async () => {
    try {
      await getTestimonial()
        .then((response: any) => {
       
          setreviews(response.data);
        })
        .catch(() => {
          console.log("T&C ");
        });
    } catch (error) {
      console.log("T&C ", error);
    }
  };

  useEffect(() => {
    getTestimonials();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    }, 1500);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      // const data = await getFilters();
      const data2 = await getItemProducts2();

      await getFilters();
    };

    fetchData();
  }, [selectedCat]);

  useEffect(() => {
    // if (allSubs.length !== 0) {
    const fetchData = async () => {
      const data2 = await getItemProducts2();
      await getFilters2();
    };

    fetchData();
    // }
  }, [
    selectedSub,
    allSubs,
    filterSortValue,
    pageLimit,
    currentPage,
    selectedColour,
    selectedSize,
    minPrice,
    maxPrice,
    search
  ]);

  const [isOpen, setIsOpen] = useState(false);

  const handleSelect = (value: any) => {
    setFilterSortValue(value);
    setIsOpen(false);
  };

  useEffect(() => {}, [selectedSubCat]);
  const DATA_sortOrderRadios = [
    // { name: "Most Popular", id: "featured" },
    // { name: "Best Rating", id: "Best-Rating" },
    { name: "Newest", id: "latest" },
    { name: "Price Low - Hight", id: "LowToHigh" },
    { name: "Price Hight - Low", id: "HighToLow" }
  ];

  const getFilters = async () => {
    const ob = {
      sort: {
        sortField: "productName",
        sort: "asc"
      },
      categories:
        selectedCat === "" ? JSON.stringify([]) : JSON.stringify([selectedCat]),
      distinct: "0"
    };
    // setlopen(true);

    await getAllProducts(ob)
      .then((res) => {
        const allColors = _.uniqBy(
          res?.data?.result,
          function (e: { clolour: any }) {
            return e.clolour;
          }
        );
        var allColors2 = allColors?.filter(function (el: any) {
          return el.clolour !== "";
        });
        const allSizes = _.uniqBy(
          res?.data?.result,
          function (e: { size: any }) {
            return e.size;
          }
        );
        var sizeArr = allSizes?.filter(function (el: { size: string }) {
          return el.size !== "";
        });
        const allPrice = _.uniqBy(
          res?.data?.result,
          function (e: { discountedprice: any }) {
            return e.discountedprice;
          }
        );
        const allTags = _.uniqBy(
          res?.data?.result,
          function (e: { tags: any }) {
            return e.tags;
          }
        );
        const allSubs = _.uniqBy(
          res?.data?.result,
          function (e: { subcatId: any; subCategory: any; subcatDesc: any }) {
            return e.subCategory;
          }
        );

        var subsArr = allSubs?.filter(function (el: { subCategory: null }) {
          return el.subCategory !== null;
        });
        const myPrices = _.map(allPrice, "discountedprice");

        const Pricelist = _.sortBy(myPrices, [
          function (o: any) {
            return o;
          }
        ]);

        const mySizes = _.map(sizeArr, "size");
        const myColors = _.map(allColors2, "clolour");
        const myTags = _.map(allTags, "tags");
        const mySubs = _.map(subsArr, "subCategory");

        const updateMyColors = myColors?.map((item: any, index: any) => {
          return {
            id: index,
            name: item,
            status: false
          };
        });

        const updateMySubs = subsArr.map((item, index) => {
          return {
            id: item.subcatId,
            name: item.subCategory
            // description: item.subcatDesc,
          };
        });

        setallSizes(mySizes);
        setallColours(myColors);
        settags(myTags);
        setallSubs(updateMySubs);

        let Id2: any = localStorage.getItem("sSub");
        setselectedSub(Id2 ? parseInt(Id2) : "");
        // this.setState({
        //   color: updateMyColors,
        //   size: mySizes,
        //   min: Pricelist[0],
        //   max: Pricelist[Pricelist.length - 1],
        //   range: {
        //     min: Pricelist[0],
        //     max: Pricelist[Pricelist.length - 1],
        //   },
        // });
      })
      .catch(() => {
        setlopen(false);
      });
  };

  const getFilters2 = async () => {
    const ob = {
      sort: {
        sortField: "productName",
        sort: "asc"
      },
      categories: JSON.stringify([]),
      distinct: "1",
      search: search2
    };
    // setlopen(true);

    await getAllProducts(ob)
      .then((res) => {
        const allProd = res.data.result;

        setallProd(allProd);
      })
      .catch(() => {
        setlopen(false);
      });
  };

  // useEffect(() => {
  //   getFilters2();
  // }, [search2]);

  const getItemProducts = async () => {
    let sort = {
      sortField: "productName",
      sort: "asc"
    };
    let latest = "";
    let featured = "";

    if (filterSortValue === "HighToLow") {
      sort = {
        sortField: "mrp",
        sort: "desc"
      };
    } else if (filterSortValue === "LowToHigh") {
      sort = {
        sortField: "mrp",
        sort: "asc"
      };
    } else if (filterSortValue === "latest") {
      latest = "1";
    } else if (filterSortValue === "featured") {
      featured = "1";
    } else {
      sort = {
        sortField: "productName",
        sort: "asc"
      };
      latest = "";
      featured = "";
    }

    // console.log(sortType)

    const obj = {
      sort: sort,
      categories:
        selectedCat === "" ? JSON.stringify([]) : JSON.stringify([selectedCat]),
      subId:
        selectedSub === "" ? JSON.stringify([]) : JSON.stringify([selectedSub]),
      search: search,
      latest: latest,
      featured: featured,
      pNo: currentPage.toString(),
      limit: pageLimit.toString(),
      color: selectedColour,
      size: selectedSize,
      pto: maxPrice,
      pfrom: minPrice
    };

    try {
      await getAllProducts(obj)
        .then((response) => {
          // setlopen(false);

          let m =
            Math.ceil(parseFloat(response.data.count[0].pages)) === 0
              ? 1
              : Math.ceil(parseFloat(response.data.count[0].pages));

          setnProducts(response.data.result);
          setcount(response.data.count[0].count);
          // this.setState(
          //   {
          //     array: res.data.result,
          //     filter: res.data.result,
          //     showPages: m,
          //     showOff: true,
          //   },
          //   async () => {
          //     await this.getPaginationGroup(m);
          //   }
          // );
        })
        .catch(() => {
          setnProducts([]);
          setcount(0);
          // setlopen(false);
          // this.setState({
          //   array: [],
          //   filter: [],
          //   showPages: 1,
          //   showOff: false,
          // });
        });
    } catch (error) {
      console.log("T&C ", error);
    }
  };

  const getItemProducts2 = async () => {
    let sort = {
      sortField: "productName",
      sort: "asc"
    };
    let latest = "";
    let featured = "";

    if (filterSortValue === "HighToLow") {
      sort = {
        sortField: "unitPrice",
        sort: "desc"
      };
    } else if (filterSortValue === "LowToHigh") {
      sort = {
        sortField: "unitPrice",
        sort: "asc"
      };
    } else if (filterSortValue === "latest") {
      latest = "1";
    } else if (filterSortValue === "featured") {
      featured = "1";
    } else {
      sort = {
        sortField: "productName",
        sort: "asc"
      };
      latest = "";
      featured = "";
    }

    // console.log(sortType)

    const obj = {
      sort: sort,
      categories:
        selectedCat === "" ? JSON.stringify([]) : JSON.stringify([selectedCat]),
      subId:
        selectedSub === "" ? JSON.stringify([]) : JSON.stringify([selectedSub]),
      search: search,
      latest: latest,
      featured: featured,
      pNo: currentPage.toString(),
      limit: pageLimit.toString(),
      color: selectedColour,
      size: selectedSize,
      pto: maxPrice,
      pfrom: minPrice
    };

    try {
      // setlopen(true);
      await getAllProducts(obj)
        .then((response) => {
          setlopen(false);

          let m =
            Math.ceil(parseFloat(response.data.count[0].pages)) === 0
              ? 1
              : Math.ceil(parseFloat(response.data.count[0].pages));

          setnProducts(response.data.result);
          setcount(response.data.count[0].count);
          // this.setState(
          //   {
          //     array: res.data.result,
          //     filter: res.data.result,
          //     showPages: m,
          //     showOff: true,
          //   },
          //   async () => {
          //     await this.getPaginationGroup(m);
          //   }
          // );
        })
        .catch(() => {
          setnProducts([]);
          setcount(0);
          setlopen(false);
          // this.setState({
          //   array: [],
          //   filter: [],
          //   showPages: 1,
          //   showOff: false,
          // });
        });
    } catch (error) {
      console.log("T&C ", error);
    }
  };

  useEffect(() => {}, [selectedCat]);

  useEffect(() => {
    let Id: any = localStorage.getItem("sMain");
    let Id2: any = localStorage.getItem("sSub");

    if (
      Id &&
      Id !== undefined &&
      // || (Id2 && Id2 !== undefined)
      categories.length !== 0 &&
      nProducts.length !== 0
    ) {
      setselectedCat(parseInt(Id));
      // setselectedSub(Id2 ? parseInt(Id2) : "");

      Categories()
        .then((response) => {
          var subsArr = response?.data?.filter(function (el: {
            mainId: number;
          }) {
            return el.mainId === parseInt(Id!);
          });

          setmainDesc(subsArr[0]);
          setScat(true);
        })
        .catch(() => {
          setScat(true);
        });
    }

    // return () => {
    //   localStorage.removeItem("sMain");
    // };
  }, [categories, nProducts]);

  useEffect(() => {
    // if (Scat === true) {
    //   localStorage.removeItem("sMain");
    //   localStorage.removeItem("sSub");
    //   setScat(false);
    // }
    // return () => {
    //   localStorage.removeItem("sMain");
    //   localStorage.removeItem("sSub");
    // }
  }, [Scat]);

  React.useEffect(() => {
    if (selectedCat !== 4) {
      localStorage.setItem("sMain", selectedCat);
    }
  }, [selectedCat]);

  const getCurrentUser = () => {
    CurrentUser()
      .then((res) => {
        if (res.statusCode === 200) {
          const cData = res.data[0];
          const obj = {
            id: cData.id,
            email: cData?.email,
            firstName: cData?.firstName,
            lastName: cData?.lastName,
            phone: cData?.mobileNumber,
            companyAddress: cData?.companyAddress
          };
          //   this.setState({
          //     firstName: cData?.firstName,
          //     lastName: cData?.lastName,
          //     email: cData?.email,
          //     tele: cData?.phone,
          //   });

          setcurrUser(cData);
          // setName(cData?.firstName + " " + cData?.lastName);

          localStorage.setItem(
            `${window.location.hostname}.profile`,
            JSON.stringify(obj)
          );
        }
      })
      .catch((error) => {
        console.log("error", error);
        console.log("current user error", error.response.data.message);
      });
  };


  const getDeliveryDate = () => {
    getDeliveryDateByCart(currUser?.company_id)
      .then((res) => {
        setdeliveryDate(res.data?.nextDeliveryDate);
      })
      .catch((error) => {
        console.log("Error", error.response);
        setdeliveryDate("");
        // setOpenToast(true);
        // setColor(COLORS.Orange);
        // setAlertClass("error");
        // setMsg(error.response.data.message);
      });
  };

  React.useEffect(() => {
    getCurrentUser();
  }, []);

  React.useEffect(() => {
    getDeliveryDate();
  }, [currUser]);

  return (
    <div
      className={`nc-PageCollection2 ${className}`}
      data-nc-id="PageCollection2"
    >
      <LoadingSpinner open={lopen} />
      <Helmet>
        <title>Shop | Silvermill</title>
        <meta
          name="description"
          content="Welcome to Oz Cleaning Supplies, one of the most reliable sources for commercial &
residential cleaning products suppliers and cleaning chemicals supplies in
Dandenong!"
        />

        <meta
          name="keywords"
          content="Cleaning products suppliers Dandenong, Cleaning chemicals supplies Dandenong"
        />
        <link rel="canonical" href={path + "/"} />

        <script type="application/ld+json">{`
  {
  "@context" : "http://schema.org",
  "@type" : "LocalBusiness",
  "name": "Oz Cleaning Supplies",
  "image" : [ "https://backendozecomadmin.ozcleaningsupplies.com.au/uploads/logo/1670255682167.jpeg", "https://www.ozcleaningsupplies.com.au/" ],
  "telephone": "+61 0397940654",
  "email": "info@ozcleaningsupplies.com.au",
  "address": {
    "@type": "PostalAddress",
    "streetAddress" : "167 Cheltenham Road",
    "addressLocality": "Dandenong",
    "addressRegion" : "VIC ",
    "addressCountry": "Australia",
    "postalCode" : "3175"
  },
  "url": "https://www.ozcleaningsupplies.com.au${path}"
}
    `}</script>
      </Helmet>

      <div className="  ">
        <div
          className="w-full relative flex flex-col pt-8 gap-5 justify-center font-berthold items-center bg-[#ffe1e7]"
          style={{
            backgroundImage: `url(${heroimg})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            minHeight: "100vh", // Will adjust based on content
            aspectRatio: "auto" // Adjust height based on aspect ratio if needed
          }}
        >
          <CustomSwiper />
        </div>

        <div className="w-full flex flex-col">
          <img src={productTitle} alt="" />
        </div>
        <div className="px-[8%] font-berthold space-y-6 py-10 md:py-16 md:pt-5 lg:space-y-6">
          {/* HEADING */}
          {/* <div className="w-full flex flex-col gap-3  custom-shadow p-5 px-7 md:p-10 md:px-14 rounded-lg">
            {/* <h1 className="mb-8 text-2xl">
              Best Cleaning Products And Cleaning Chemicals Supplies Dandenong
            </h1> *
            <h2 className="block font-berthold text-base sm:text-2xl lg:text-lg font-semibold">
              Portal Access and Guidelines
            </h2>
            <ul className="list-disc text-[#545454] text-xs font-light md:text-sm pl-2 md:pl-5">
              <li className="mb-1">
                This is an internal portal strictly for authorized users.
                Sharing access with external parties is prohibited.
              </li>
              <li className="mb-1">
                To continue shopping, please register or log in using your
                unique ID. You can obtain this ID from the [XXXX Department] in
                your company.
              </li>
              <li className="mb-1">
                <span className=" text-red-600">Important</span>: Your unique ID
                and password must not be shared with anyone outside the company.
                If any violations are detected, your account will be permanently
                deleted, and you will not be allowed to re-register in the
                future.
              </li>
              <li className="mb-1">
                Some products have purchase limits, which will be indicated
                during your shopping process.
              </li>
              <li className="mb-1">
                You will receive notifications once your order is placed, along
                with the expected delivery date.
              </li>
              <li className="mb-1">
                For any issues with orders, account access, or login, please
                contact our support team at [XXXXX].
              </li>
            </ul>
          </div> */}

          {/* <hr className="border-slate-200 dark:border-slate-700" /> */}

          <main className="md:pt-5">
            {/* LOOP ITEMS */}
            {/* <div className="flex w-full flex-col md:flex-row justify-between gap-3 md:gap-0 mt-10 md:mt-2 items-center mb-10">
              <div className="px-5 md:hidden py-3 rounded-lg text-white text-sm bg-gradient-to-r from-[#010a4f] to-[#e63a42]">
                {deliveryDate
                  ? `Your order is expected to be delivered on ${deliveryDate}. However, you will receive an SMS notification confirming the delivery date.`
                  : `Please login  to check dlivery date`}
              </div>
     

            </div> */}
            <div className="flex flex-col lg:flex-row">
              <div className="lg:w-1/3 xl:w-1/4 pr-4">
                <SidebarFilters
                  categories={categories}
                  setSelectedcat={setselectedCat}
                  selectedCat={selectedCat}
                  allSubs={allSubs}
                  setselectedSub={setselectedSub}
                  selectedSub={selectedSub}
                  allSizes={allSizes}
                  selectedSize={selectedSize}
                  setselectedSize={setselectedSize}
                  allColours={allColours}
                  selectedColour={selectedColour}
                  setselectedColour={setselectedColour}
                  setminPrice={setminPrice}
                  minPrice={minPrice}
                  setmaxPrice={setmaxPrice}
                  maxPrice={maxPrice}
                  filterSortValue={filterSortValue}
                  setFilterSortValue={setFilterSortValue}
                  setCurrentPage={setCurrentPage}
                  setmainDesc={setmainDesc}
                  setsearch={setsearch}
                  search={search}
                  allProd={allProd}
                  search2={search2}
                  setsearch2={setsearch2}
                />
              </div>
              {/* <div className="flex-shrink-0 mb-10 lg:mb-0 lg:mx-4 border-t lg:border-t-0"></div> */}
              <div className="flex-1 ">
                <div className="px-5  mb-10 py-3 rounded-lg text-white font-GlacialIndifference font-semibold text-center text-base bg-[#088c8e]">
                  {deliveryDate
                    ?(
                      <div className="">
                      Order by (Today) to receive your order on {deliveryDate}. Please note that your products will be delivered to : &nbsp;
                      
                      {currUser?.companyAddress}
                    </div>
                    ) 
                    : `Please login  to check delivery date`}

                </div>
                <div className="flex flex-wrap justify-between w-full pt-5 ">
                  {nProducts?.length > 0 &&
                    nProducts?.map((item, index) => (
                      <ProductCard item={item} currUser={currUser} />
                      // <ProductCardNew
                      //   currUser={currUser}
                      //   data2={item}
                      //   key={index}
                      // />
                    ))}
                  {nProducts.length === 0 && (
                    <span className="block mt-4 text-neutral-500 dark:text-neutral-400 text-xs sm:text-sm">
                      No Products found.
                    </span>
                  )}
                </div>
                {/* PAGINATION */}
                <div className="flex flex-col mt-12 lg:mt-16 space-y-5 sm:space-y-0 sm:space-x-3 sm:flex-row sm:justify-center sm:items-center">
                  <Pagination
                    pageLimit={pageLimit}
                    dataCount={dataCount}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                  />
                    <button
      onClick={() => {
        setpageLimit(pageLimit + 3);
      }}
        className={`font-berthold  w-fit px-5 py-1 text-xl font-medium rounded-lg uppercase
           bg-[#010a4f] text-white`}
      >
       Load More
      </button>
                  {/* <button
                    className="ttnc-ButtonPrimary px-5 py-2 bg-[#cd294b] dark:bg-slate-100 hover:bg-slate-100 hover:text-[#20254a] text-slate-50 dark:text-slate-800 rounded-md shadow-xl"
                    // loading
                    onClick={() => {
                      setpageLimit(pageLimit + 3);
                    }}
                  >
                    Load more
                  </button> */}
                </div>
              </div>
            </div>
          </main>
        </div>
        <div className="w-full flex flex-col">
          <img src={pageBottom} alt="" />
        </div>
        <div className="bg-[#010a4f] pt-[5%] flex flex-col items-center">
          <div className=" text-2xl md:text-5xl font-berthold text-[#08afb2] ">
            Don't just take our word for it
          </div>
          <div className="text-4xl md:text-6xl font-berthold text-center md:text-start text-white uppercase">
            Here's what OUR CUSTOMERS SAY...
          </div>
          <div className=" flex flex-col w-full items-center px-[10%] pt-[5%] ">
            <Swiper
              spaceBetween={30}
              slidesPerView={3}
              loop={true}
              pagination={{
                clickable: true,
                el: ".custom-pagination"
              }}
              modules={[SwsPagintion]}
              breakpoints={{
                640: {
                  slidesPerView: 1 // 1 slide on mobile
                },
                768: {
                  slidesPerView: 2 // 2 slides on tablets
                },
                1024: {
                  slidesPerView: 3 // 3 slides on desktop
                }
              }}
              className="flex w-full text-white"
            >
              {reviews?.length > 0 &&
                reviews?.map((item: any, index) => {
                  return (
                    <SwiperSlide className="w-[30%] flex ">
                      <img
                        src={`${IMAGE_SERVER_URL_DEV}${item.image}`}
                        alt=""
                      />
                    </SwiperSlide>
                  );
                })}

              {/* <SwiperSlide className="w-[30%] flex ">
              <img src={reviewimg} alt="" />
            </SwiperSlide>
            <SwiperSlide className="w-[30%] flex ">
              <img src={reviewimg} alt="" />
            </SwiperSlide>
            <SwiperSlide className="w-[30%] flex ">
              <img src={reviewimg} alt="" />
            </SwiperSlide> */}
            </Swiper>

            <div className="custom-pagination mt-8 flex justify-center space-x-3">
              {/* Pagination dots are automatically generated by Swiper */}
            </div>

            <div className="mt-16 font-justhand tracking-wide w-fit px-5 py-2 text-2xl md:text-4xl  rounded-lg  bg-[#010a4f] text-white">
              All comments are those made by Silvermill customers on our social
              media platforms
            </div>
          </div>
        </div>
        {/* SUBCRIBES */}
      </div>

      {/* <SectionPromo1 /> */}
    </div>
  );
};

const ProductCard = ({ item, currUser }: { item: any; currUser?: any }) => {
  const [value, setValue] = useState<any | null>(0);
  const [lopen, setlopen] = React.useState(true);
  useEffect(() => {
    setValue(1);
  }, [1]);
  const { cart, setCart } = useContext(CartContext);
  const handleClickDecrement = () => {
    if (1 >= value) return;
    setValue((state: number) => {
      return state - 1;
    });
  };
  const handleClickIncrement = () => {
    if (99 <= value) return;
    setValue((state: number) => {
      return state + 1;
    });
  };


  const notifyAddTocart = () => {
    const getToken: any = localStorage.getItem(
      `${window.location.hostname}.login`
    );

    // if (!getToken) {
    //   history.push("/login");
    //   window.scroll(0, 0);
    //   return true;
    // }

    let tempId: any = "";

    let userId: any = "";

    let userstatus: any = 0;

    if (localStorage.getItem("tempUser")) {
      tempId = localStorage.getItem("tempUser");
    }

    if (getToken) {
      const { data } = JSON.parse(getToken);
      const { id }: any = jwtDecode(data);
      const { status }: any = jwtDecode(data);
      userId = id;
      userstatus = status;
    }

    let error = false;
    let cartDetails = {
      productId: item?.id.toString(),
      userId: userId ? userId : tempId,
      buyone: item?.buyone,
      qty: value,
      color: "",
      size: "",
      fileType: "",
      image: ""
    };

    if (userId) {
      if (userstatus === 1) {
        addToCard(cartDetails)
          .then((data) => {
            setlopen(false);
            // addToast(`${product?.productName} Added to Cart`, { appearance: "success", autoDismiss: true });
            toast.custom(
              (t) => (
                <NotifyAddTocart
                  productImage={`${IMAGE_SERVER_URL_DEV}${
                    JSON.parse(item?.imgArray)[0].path
                  }`}
                  qualitySelected={value}
                  show={t.visible}
                  sizeSelected={""}
                  variantActive={0}
                  selectedProductColor={""}
                  selectedProduct={item}
                />
              ),
              { position: "top-right", id: "nc-product-notify", duration: 3000 }
            );
            getCartList().then((res) => {
              setCart(res.data?.cart_items);
              // console.log(res.data?.cart_items)
            });
            // this.setState({
            //   color2: "#645bff",
            //   alertClass: "success",
            //   msg: `${this.state.productData?.displayName} Added to Cart`,
            //   open_toast: true,
            // });
          })
          .catch((err) => {
            setlopen(false);
            addToast(`${err.response.data.message}`, "warning");
            // this.setState({
            //   color2: "#645bff",
            //   alertClass: "success",
            //   msg: `${this.state.productData?.displayName} product add failed`,
            //   open_toast: true,
            // });
          });
      } else {
        addToast(`Your acccount is not activated`, "warning");
      }
    } else {
      addToast(`Please Log in to buy products`, "warning");
    }
  };
  let discount = (
    ((parseFloat(item.mrp) - parseFloat(item.specialprice)) /
      parseFloat(item.mrp)) *
    100
  ).toString();

  return (
    <div className="w-full relative md:w-[30%] p-5 mb-16 bg-[#fffaef] rounded-2xl flex flex-col items-center gap-2 justify-start pt-8 ">
      {item?.specialprice && (
        <div className="absolute right-[-11%] z-[80] top-[-10%]">
          <div className="relative flex justify-center items-center w-[90px] h-[90px]">
          
            <img src={save} className="w-full" alt="" />
        
          
            <div className="absolute inset-0 flex items-center font-berthold pl-2 flex-col justify-center text-center text-white text-sm leading-tight transform rotate-[25deg] -translate-y-2">
              <div>SAVE</div>
              <div className="text-[24px]">{parseInt(discount)}%</div>
            </div>
          </div>
        </div>
      )}

      <Link
        to={`/product-detail/${item?.productId}`}
        className="text-[#010a4f] font-berthold text-center text-2xl"
      >
        {item?.productName}
      </Link>
      {/* <div className="text-[#010a4f] font-berthold text-center text-2xl pb-3">
      500 ml
    </div> */}
      <div className="w-full rounded-lg aspect-1 overflow-hidden">
      {item?.imgArray && (
        <img
          src={`${IMAGE_SERVER_URL_DEV}${JSON.parse(item?.imgArray)[0].path}`}
          className="  w-full"
          alt=""
        />
      )}
      </div>
     

      {item?.buyone === 1 && (
        <div className=" font-berthold text-red-600">Buy One Get 1 FREE</div>
      )}

      <div className=" w-full flex justify-center font-berthold text-[#010a4f] text-2xl items-end gap-2 ">
        <div
          className={`${
            item?.specialprice && " text-[22px] text-[#8a8888] line-through "
          }`}
        >
          Rs.{item?.mrp}
        </div>
        {item?.specialprice && <div className="">Rs.{item?.specialprice}</div>}{" "}
        / Unit
      </div>
      <Link
        to={`/product-detail/${item?.productId}`}
        className={`font-berthold  w-fit px-5 py-1 text-xl font-medium rounded-lg uppercase
           bg-[#010a4f] text-white`}
      >
        View Product
      </Link>

      <>
        <div className="flex gap-2 items-center mt-1">
          <div className="flex  text-[#010a4f] text-sm items-center rounded-md border-2 border-[#8a8888] px-3 py-[2px] gap-3">
            <button className="text-xl text-[#010a4f]" onClick={handleClickDecrement} disabled={1 >= value}>
             -
            </button>
            <span>{value}</span>
            <button
              className="text-[#010a4f] text-xl"
              disabled={!currUser}
              onClick={handleClickIncrement}
            >
              +
            </button>
          </div>
          <button
            // disabled={!currUser}
            onClick={notifyAddTocart}
            className={`font-berthold  w-fit px-5 py-1 text-xl font-medium rounded-lg uppercase ${
              currUser ? "bg-[#02871a]" : "bg-[#02871a]"
            }  text-white`}
          >
            Add to cart
          </button>
        </div>
      </>
    </div>
  );
};

function CustomSwiper() {
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <div className=" px-[5%] flex flex-col items-center ">
      <Swiper
        spaceBetween={30}
        slidesPerView={"auto"}
        loop={true}
        pagination={{
          clickable: true,
          el: ".custom-pagination-m" // Use the custom pagination element
        }}
        modules={[SwsPagintion]}
        onSlideChange={(swiper: SwiperCore) => setActiveIndex(swiper.realIndex)} // Update active index
        className="flex w-full pb-10 "
      >
        <SwiperSlide>
          <img src={notime6} alt="Slide 1" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={notime7} alt="Slide 2" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={notime8} alt="Slide 3" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={notime9} alt="Slide 4" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={notime10} alt="Slide 5" />
        </SwiperSlide>
      </Swiper>

      {/* Custom Pagination */}
      <div className="custom-pagination-m mt-8 flex justify-center space-x-3">
        {[0, 1, 2].map((index) => (
          <button
            key={index}
            onClick={() => setActiveIndex(index)}
            className={`w-3 h-3 rounded-full ${
              activeIndex === index ? "bg-[#010a4f]" : "bg-gray-300"
            }`}
          />
        ))}
      </div>
    </div>
  );
}

export default PageCollection2;
