import {
  IMAGE_SERVER_URL,
  IMAGE_SERVER_URL_DEV,
  IMAGE_SERVER_URL_DEV2
} from "../../Constant/System";
import _ from "lodash";
var moment = require("moment");

function dynamicSort(property) {
  var sortOrder = 1;
  if (property[0] === "-") {
    sortOrder = -1;
    property = property.substr(1);
  }
  return function (a, b) {
    var result =
      a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
    return result * sortOrder;
  };
}
function groupByKey(array, key) {
  return array.reduce((hash, obj) => {
    if (obj[key] === undefined) return hash;
    return Object.assign(hash, {
      [obj[key]]: (hash[obj[key]] || []).concat(obj)
    });
  }, {});
}

export const EMAIL_TAMPLETE2 = ({
  customerName,
  address,
  invoiceNo,
  date,
  tax,
  MESSAGE,
  // address2,
  // address3,
  // address4,
  iUsers = [],
  currency,
  amount,
  Total_Mrp_Value,
  Total_Saving,
  delType,
  mobileNumber
}) => {
  return `
 <!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <title>Invoice</title>
<link rel="preconnect" href="https://fonts.googleapis.com">
<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <style>
    
      body {
        font-family: "Montserrat", sans-serif;
        font-style: normal;
        margin: 5px;
        padding: 0;
        color: #333;
      }

       * {
    font-style: normal !important; /* Ensures no text is italic by default */
  }

      .invoice-container {
        width: 100%;
      
        margin: 20px 5px;
        padding: 20px;
     
      }
      .header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding-bottom: 10px;
        position: relative;
      }
      .header-left {
        width: 50%;
      }

      .header-left h1 {
        font-size: 32px;
        margin: 0;
        color: #001f54;
      }
      .header-right {
        width: 50%;
        display: flex;
        justify-content: flex-end;
      }
      .header-right img {
        max-width: 200px;
        position: absolute;
        top: 0;
        right: 15px;
      }

      .company-details {
        margin-top: 5px;
        color: #001f54;
      }
    .title {
        font-weight: 600;
      }
    .title-2 {
        font-weight: 600;
        text-align: center;
        font-size: 16px;
        margin-top: 20px;
        color: #001f54;
      }
      .company-details p {
        margin: 0;
        font-size: 14px;
        line-height: 1.5;
      }
      .company-details p img{
      width : 20px;
      height : 20px;
      }

      .invoice-details {
        margin-top: 160px;
        display: flex;
        gap: 3px;
        flex-direction: column;
      }

      .invoice-item {
        display: flex;
        gap: 10px;
        margin-top: 10px;
        align-items: center;

        color: #001f54;
        font-weight: 500;
      }
      .invoice-item span {
        font-weight: 500;
         font-size: 12px;
      }

      .table {
        width: 95%;
        margin-top: 20px;
         font-size: 10px;
        border-collapse: collapse; /* Ensures proper border rendering */
      }

      .table th,
      .table td {
        border-left: 1px solid #001f54; /* Uniform border on all sides */
        text-align: right;
        padding: 5px;
        font-size: 10px;
      }

      .table th {
        border-left: 1px solid white;
        background-color: #001f54;
        color: white;
        font-weight: bold;
        padding: 10px;
        text-align: center;
      }

      .table tbody tr:last-child td {
        border-bottom: 1px solid #001f54; /* Ensure the last row has a bottom border */
      }
      .table tbody tr td:first-child {
        text-align: left;
      }

      .table tbody tr td:last-child {
        border-right: 1px solid #001f54; /* Ensure the last row has a bottom border */
      }

      .table tbody tr td:last-child {
        font-weight: bold; /* Style for the last column */
      }
      .grand-t {
        border-top: 1px solid #001f54;
        font-weight: bold;
      }

      .mt-20{
        margin-top: 20px;
      }
    </style>
  </head>
  <body>
    <div class="invoice-container">
   

      <div class="invoice-details">
        <div class="invoice-item">
          <span>Date:</span>
          <span> ${moment(new Date(date)).format("DD/MM/YYYY")}</span>
        </div>
        <div class="invoice-item">
          <span>Cusstomer Name:</span>
          <span>   ${customerName}</span>
        </div>
        <div class="invoice-item">
          <span>Corparate Partner:</span>
          <span>2024-05-01</span>
        </div>
        <div class="invoice-item">
          <span>Order Number:</span>
          <span> ${invoiceNo}</span>
        </div>
      </div>

      <table class="table">
        <thead>
          <tr>
            <th>Item Code</th>
            <th>Description</th>
            <th>MRP</th>
            <th>Unit Price</th>
            <th>QTY.</th>
            <th>Net Value</th>
          </tr>
        </thead>
        <tbody>
         ${iUsers
           ?.map((item, index) => {
             const productName = item?.productName;
             const mrp = parseFloat(item.mrp);
             const specialPrice = item?.specialprice
               ? parseFloat(item.specialprice)
               : null;
             const paidQty = item?.buyone ? Math.ceil(item.qty / 2) : item.qty;
             const freeQty = item?.buyone ? Math.floor(item.qty / 2) : 0;
             const totalPrice = (specialPrice || mrp) * paidQty;

             return `
  <tr>
            <td>${productName} x ${paidQty}</td>
            <td>${productName} x ${paidQty}</td>
           
            <td>${mrp.toFixed(2)}</td>
            <td>
            ${specialPrice ? specialPrice.toFixed(2) : mrp.toFixed(2)}</td>

            <td>${paidQty}</td>
            <td>${currency} ${totalPrice.toFixed(2)}</td>
          </tr>

           ${
             freeQty > 0
               ? `<tr style="border-bottom: 1px solid #ddd;">
                <td>${productName} x  (Free)</td>
                <td>${productName} x  (Free)</td>
                 <td>${mrp.toFixed(2)}</td>
 <td>
            ${specialPrice ? specialPrice.toFixed(2) : mrp.toFixed(2)}</td>
           <td>${freeQty}</td>
                 <td style="padding: 8px; text-align:right;">${currency} 0.00</td>
               </tr>`
               : ""
           }
         `;
           })
           .join("")}
        
        
          <tr class="grand-t">
            <td colspan="5">Grand Total</td>
            <td>${currency} ${parseFloat(amount).toFixed(2)}</td>
          </tr>
        </tbody>
      </table>
      <div class="invoice-details">
        <div class="invoice-item">
          <span> No of Products in this order: 
            </span>
          <span>${iUsers.length}</span>
        </div>
        <div class="invoice-item">
          <span> No of bags for this order:</span>
          <span>1</span>
        </div>
        <div class="invoice-item mt-20">
          <span>You have saved Rs.    ${currency} ${parseFloat(
    Total_Saving
  ).toFixed(2)}         from this order:</span>
       
        </div>
      
      </div>

     
    </div>
  </body>
</html>
`;
};


//    <br/>
//   ${address2}
//   <br/>
//   ${address3}
//   <br/>
//   ${address4}
