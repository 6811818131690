import React, { FC } from "react";
import MainNav2Logged from "./MainNav2Logged";
import MainNavNew from "./MainNavNew";

export interface HeaderLoggedProps {}

const HeaderLogged: FC<HeaderLoggedProps> = () => {
  return (
    <div className="nc-HeaderLogged  sticky top-0 w-full z-[100] ">
      <MainNavNew />
    </div>
  );
};

export default HeaderLogged;
