import { CustomLink } from "data/types";
import React, { FC } from "react";
import { Link } from "react-router-dom";
import twFocusClass from "utils/twFocusClass";

const DEMO_PAGINATION: CustomLink[] = [
  {
    label: "1",
    href: "#",
  },
  {
    label: "2",
    href: "#",
  },
  {
    label: "3",
    href: "#",
  },
  {
    label: "4",
    href: "#",
  },
];

export interface PaginationProps {
  className?: string;
  pageLimit?: any;
  dataCount?: any;
  currentPage?: any;
  setCurrentPage?: any
}

const Pagination: FC<PaginationProps> = ({
  className = "",
  pageLimit,
  dataCount,
  currentPage,
  setCurrentPage
}) => {
  const pageNumbers: any = [];
  let maxNo = 1
  let minNo = 1
  let allPages = Math.ceil(parseFloat(dataCount) / parseFloat(pageLimit))


  if (parseFloat(currentPage) >= 2) {
    for (let i = currentPage - 1; i <= Math.ceil(parseFloat(currentPage) + 8); i++) {

      if (i <= allPages && i > 0) {
        pageNumbers.push(i);
      }
      //  console.log(" pageNumber next -----**",pageNumbers)

    }

  }
  else {
    for (let i = 1; i <= Math.ceil(parseFloat(currentPage) + (10 - parseFloat(currentPage))); i++) {
      if (i <= allPages) {
        pageNumbers.push(i);
      }
      //  console.log(" pageNumber next -----**",pageNumbers)

    }
  }

  const renderItem = (pag: any, index: number) => {
    if (pag == currentPage) {
      // RETURN ACTIVE PAGINATION
      return (
        <span
          key={index}
          className={`inline-flex w-11 h-11 items-center justify-center rounded-full bg-[#20254a] text-white ${twFocusClass()}`}
        >
          {pag}
        </span>
      );
    }
    // RETURN UNACTIVE PAGINATION
    return (
      <Link
        key={index}
        className={`inline-flex w-11 h-11 items-center justify-center rounded-full bg-white hover:bg-neutral-100 border border-neutral-200 text-neutral-6000 dark:text-neutral-400 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:border-neutral-700 ${twFocusClass()}`}
        to={pag.href}
        onClick={() => {
          setCurrentPage(pag);
          window.scrollTo({
            top: 0,
            behavior: "smooth"
          });
        }}
      >
        {pag}
      </Link>
    );
  };

  return (
    <>
    {
      Math.ceil(parseFloat(dataCount) / parseFloat(pageLimit)) !== 0 &&

      <nav
      className={`nc-Pagination inline-flex space-x-1 text-base font-medium ${className}`}
    >
      {/* <span

        className={`inline-flex w-11 h-11 items-center justify-center rounded-full bg-white hover:bg-neutral-100 border border-neutral-200 text-neutral-6000 dark:text-neutral-400 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:border-neutral-700 ${twFocusClass()} cursor-pointer`}
        onClick={(e) => {

          setCurrentPage(Math.min(...pageNumbers));
          window.scrollTo({
            top: 100,
            left: 0,
            behavior: 'smooth'
          });

        }}
      >
        {"<<"}
      </span> */}
      {
        currentPage !== Math.min(...pageNumbers) &&


        <span

          className={`inline-flex w-11 h-11 items-center justify-center rounded-full bg-white hover:bg-neutral-100 border border-neutral-200 text-neutral-6000 dark:text-neutral-400 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:border-neutral-700 ${twFocusClass()} cursor-pointer`}
          onClick={() => {
            if (!(currentPage === Math.min(...pageNumbers))) {
              setCurrentPage(currentPage - 1);
            }
            window.scrollTo({
              top: 100,
              left: 0,
              behavior: 'smooth'
            });
          }}

        >
          {"<"}
        </span>

      }

      {pageNumbers.map(renderItem)}
      {
        currentPage !== Math.max(...pageNumbers) &&

        <span

          className={`inline-flex w-11 h-11 items-center justify-center rounded-full bg-white hover:bg-neutral-100 border border-neutral-200 text-neutral-6000 dark:text-neutral-400 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:border-neutral-700 ${twFocusClass()} cursor-pointer`}
          onClick={() => {
            if (!(currentPage === Math.max(...pageNumbers))) {
              setCurrentPage(currentPage + 1);
            }
            window.scrollTo({
              top: 100,
              left: 0,
              behavior: 'smooth'
            });
          }}

        >
          {">"}
        </span>


      }

    </nav>

      
    }
    </>
 
  );
};

export default Pagination;
