import React, { FC, useState, useEffect } from "react";
import facebookSvg from "images/Facebook.svg";
import twitterSvg from "images/Twitter.svg";
import googleSvg from "images/Google.svg";
import { Helmet } from "react-helmet";
import Input from "shared/Input/Input";
import { Link, useHistory } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { addToast } from "shared/Toast";
import { LoginQuery, postContacts } from "Services/API/Post";
import jwt_decode from "jwt-decode";
import newbackground from "images/newbackground.png";
import login from "images/login.png";
import contacthero from "images/contacthero.png";

import pageBottom from "images/pagebottom.png";
import pagetop from "images/page-top.png";
import Textarea from "shared/Textarea/Textarea";

export interface PageNewContactProps {
  className?: string;
}

const loginSocials = [
  {
    name: "Continue with Facebook",
    href: "#",
    icon: facebookSvg
  },
  {
    name: "Continue with Twitter",
    href: "#",
    icon: twitterSvg
  },
  {
    name: "Continue with Google",
    href: "#",
    icon: googleSvg
  }
];

const PageNewContact: FC<PageNewContactProps> = ({ className = "" }) => {
  const history = useHistory();
  const [lopen, setlopen] = React.useState(false);

  const [fullName, setfullName] = useState("");
  const [employeerName, setemployeerName] = useState("");
  const [mobile, setmobile] = useState("");
  const [email, setemail] = useState("");
  const [message, setmessage] = useState("");


  const [errfullName, seterrfullName] = useState(false);
  const [erremployernName, seterremployernName] = useState(false);
  const [errmobile, seterrmobile] = useState(false);
  const [erremail, seterremail] = useState(false);
  const [errmessage, seterrmessage] = useState(false);

  const contactSubmit = async (e: any) => {
    e.preventDefault();

    console.log("object")
    if (fullName === "") {
      seterrfullName(true);
    }

    if (employeerName === "") {
      seterremployernName(true);
    }
    if (email === "") {
      seterremail(true);
    }
    if (mobile === "") {
      seterrmobile(true);
    }
    if (message === "") {
      seterrmessage(true);
    }

    let obj = {
      fullName: fullName,
      employeerName: employeerName,
      email: email,
      mobile: mobile,
      message: message
    };

    if (
      fullName !== "" &&
      email !== "" &&
      employeerName !== "" &&
      mobile !== "" &&
      message !== ""
    ) {
      setlopen(true);
      postContacts(obj)
        .then((res) => {
          setlopen(false);
          console.log("res>>>", res);
          addToast(`Your enquiry has been successfully submitted`, "success");
          setfullName("");
          setemployeerName("");
          setmobile("");
          setemail("");
          setmessage("");
          
        })
        .catch((error) => {
          console.error(error);
          setlopen(false);
          addToast(error.response.data.message, "warning");
        });
    }
  };

  return (
    <div
      className={`nc-PageNewContact ${className}`}
      data-nc-id="PageNewContact"
    >
      <Helmet>
        <title>Contact || Silvermill</title>
        <meta
          name="description"
          content="Welcome to Oz Cleaning Supplies, your premier destination for top-quality cleaning
    supplies in Dandenong!"
        />

        <meta name="keywords" content="Cleaning supplies Dandenong" />
        <link rel="canonical" href={"/login/"} />

        <script type="application/ld+json">{`
  {
  "@context" : "http://schema.org",
  "@type" : "LocalBusiness",
  "name": "Oz Cleaning Supplies",
  "image" : [ "https://backendozecomadmin.ozcleaningsupplies.com.au/uploads/logo/1670255682167.jpeg", "https://www.ozcleaningsupplies.com.au/" ],
  "telephone": "+61 0397940654",
  "email": "info@ozcleaningsupplies.com.au",
  "address": {
    "@type": "PostalAddress",
    "streetAddress" : "167 Cheltenham Road",
    "addressLocality": "Dandenong",
    "addressRegion" : "VIC ",
    "addressCountry": "Australia",
    "postalCode" : "3175"
  },
  "url": "https://www.ozcleaningsupplies.com.au/login"
}
    `}</script>
      </Helmet>
      <div className=" mb-24 lg:mb-10 font-berthold">
        <div className="xl:w-full   ">
          <img className=" xl:w-full   " src={contacthero} alt="" />
        </div>
        <div className="xl:w-full   ">
          <img className=" xl:w-full   " src={pagetop} alt="" />
        </div>
        <div className="max-w-xl mx-auto space-y-6 pt-16">
        
          <div
            className="grid grid-cols-1 gap-8 bg-[#f0f0f0] rounded-lg p-10 pt-12"
        
          >
            <label className="block">
              <span className="text-[#010a4f] text-[18px] uppercase font-medium dark:text-neutral-200">
                Full Name
              </span>
              <Input
                type="text"
                placeholder=" Full Name"
                className="mt-1 font-GlacialIndifference text-[17px]"
                onChange={(e) => {
                  setfullName(e.target.value);
                  seterrfullName(false);
                }}
                value={fullName}
              />
              {errfullName && (
                <div className="text-red-600 text-xs text-right mt-2">
                  This Field Required
                </div>
              )}
            </label>
            <label className="block">
              <span className="text-[#010a4f] text-[18px] uppercase font-medium dark:text-neutral-200">
                Employer Name
              </span>
              <Input
                type="text"
                placeholder=" Employer Name"
                className="mt-1 font-GlacialIndifference text-[17px]"
                onChange={(e) => {
                  setemployeerName(e.target.value);
                  seterremployernName(false);
                }}
                value={employeerName}
              />
              {erremployernName && (
                <div className="text-red-600 text-xs text-right mt-2">
                  This Field Required
                </div>
              )}
            </label>
            <label className="block">
              <span className="text-[#010a4f] text-[18px] uppercase font-medium dark:text-neutral-200">
                Email
              </span>
              <Input
                type="text"
                placeholder="Email"
                className="mt-1 font-GlacialIndifference text-[17px]"
                onChange={(e) => {
                  setemail(e.target.value);
                  seterremail(false);
                }}
                value={email}
              />
              {erremail && (
                <div className="text-red-600 text-xs text-right mt-2">
                  This Field Required
                </div>
              )}
            </label>
            <label className="block">
              <span className="text-[#010a4f] text-[18px] uppercase font-medium dark:text-neutral-200">
                Contact Number
              </span>
              <Input
                type="text"
                placeholder="Contact Number"
                className="mt-1 font-GlacialIndifference text-[17px]"
                onChange={(e) => {
                  setmobile(e.target.value);
                  seterrmobile(false);
                }}
                value={mobile}
              />
              {errmobile && (
                <div className="text-red-600 text-xs text-right mt-2">
                  This Field Required
                </div>
              )}
            </label>
            <label className="block">
              <span className="text-[#010a4f] text-[18px] uppercase font-medium dark:text-neutral-200">
                Message
              </span>
              <Textarea
                placeholder="Type Message"
                className="mt-1 font-GlacialIndifference text-[17px]"
                onChange={(e) => {
                  setmessage(e.target.value);
                  seterrmessage(false);
                }}
                value={message}
              />
              {errmessage && (
                <div className="text-red-600 text-xs text-right mt-2">
                  This Field Required
                </div>
              )}
            </label>

            <div className=" flex justify-center w-full">
              <div className=" md:w-[48%] w-full">
                <button
                disabled={lopen}
                  className="w-full  mt-1.5 ttnc-ButtonPrimary disabled:bg-opacity-90 bg-[#20254a] dark:bg-slate-100 hover:bg-slate-100 hover:text-[#20254a] border-[#20254a] border-[1px] text-slate-50 dark:text-slate-800 rounded-md shadow-xl font-berthold py-3 "
                 onClick={contactSubmit}
                >
                 
                  <span className=" text-xl"> {lopen ? 'SENDING...' : 'SEND'}</span>
                </button>
              </div>
            </div>

          </div>

          {/* ==== */}
        </div>
      </div>
      <div className="w-full flex flex-col">
        <img src={pageBottom} alt="" />
      </div>
    </div>
  );
};

export default PageNewContact;
